import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

export type ButtonStyle = 'filled-light' | 'filled-dark' | 'outline' | 'link';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {
  @Input("locale")
  currentLocale: string = "be_nl";

  @Input("href")
  btnHref: string = "";

  @Input("btnRouterLink")
  btnRouterLink: string = "";

  @Input("target")
  btnTarget: string = "";

  @Input("rel")
  btnRel: string = "";

  @Input("style")
  btnStyle: ButtonStyle = "link";

  @Input("type")
  btnType: string = "";

  @Input("label")
  btnLabel: string = "";

  @Input("fakeBorder")
  fakeBorder: boolean = false;

  @Input('routeSegments')
  routeSegments: any[] = [];

  @Input('icon')
  icon: string = "";

  @Input()
  showIcon: boolean = true;

  @Input()
  btnBorder: string = "";

  @Output() 
  buttonClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(public translate: TranslateService, private router: Router){}

  navigateTo(event: Event) {
    if (!this.btnHref) {
      event.preventDefault();
    }
    
    if (this.routeSegments.length > 0) {
      this.router.navigate(this.routeSegments);
    }
  }

  handleClick() {
    this.buttonClick.emit();
  }
}
