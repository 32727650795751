<li id="language-switch" class="relative h-full">
    
    <!-- selected lang -->
    <div (click)="handleOpenLanguageSwitch()" 
        [ngClass]="{
            'dropdown-open': this.isOpen
        }"
        class="btn btn--large btn--outline">
        <span class="ml-auto flex gap-2 items-center">
            <span class="lg:text-base text-sm uppercase">{{ selectedLanguage }}</span>
            <app-icon [icon]="'arrow-down-icon'" [ngClass]="{'rotate-180': isOpen}" svgClass="w-3 h-2 transform translation"></app-icon>
        </span>
    </div>

    <!-- possible languages -->
    <ul class="lang-switch-dropdown"
        *ngIf="isOpen">
        <li *ngFor="let language of filteredLanguages">
            <span 
                (click)="handleSelectLanguage(language)"
                class="block hover:underline uppercase" 
                rel="nofollow">
                {{ language }}
            </span>
        </li>
    </ul>

</li>