<div class="opening-hours">

    <div class="opening-hours__nonstop">
        <div class="opening-hours__nonstop-label">
            <svg class="opening-hours__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M15,13H16.5V15.82L18.94,17.23L18.19,18.53L15,16.69V13M19,8H5V19H9.67C9.24,18.09 9,17.07 9,16A7,7 0 0,1 16,9C17.07,9 18.09,9.24 19,9.67V8M5,21C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H6V1H8V3H16V1H18V3H19A2,2 0 0,1 21,5V11.1C22.24,12.36 23,14.09 23,16A7,7 0 0,1 16,23C14.09,23 12.36,22.24 11.1,21H5M16,11.15A4.85,4.85 0 0,0 11.15,16C11.15,18.68 13.32,20.85 16,20.85A4.85,4.85 0 0,0 20.85,16C20.85,13.32 18.68,11.15 16,11.15Z"></path></svg>
            <span>{{ "fields.openingHours.label" | translate }}</span>
        </div>
        <span>
            <mat-checkbox [formControl]="this.nonstopCntr">{{ "openingHours.nonstop" | translate }}</mat-checkbox>
        </span>  
    </div>  

    <div class="opening-hours">
        <div *ngFor="let day of week" class="opening-hours__row">
            
            <div class="opening-hours__header">
                <span class="opening-hours__label">
                    {{ "weekdays."+day.weekDay.toLocaleLowerCase() | translate }}
                </span>
                <mat-slide-toggle [formControl]="day.enabled"></mat-slide-toggle>
                <div *ngIf="!day.enabled.value" class="ml-4">
                    {{ "openingHours.closed" | translate }}
                </div>
            </div>
            
            <div class="opening-hours__body">
                <div class="opening-hours__segments">
                    <div *ngFor="let segment of day.segments">

                        <div *ngIf="day.enabled.value" class="opening-hours__time">
                            <mat-form-field floatLabel="always" appearance="outline" style="max-width: 8rem;">
                                <mat-label>{{ "openingHours.openFrom" | translate }}</mat-label>
                                <input matInput type="time" [formControl]="segment.timeFrom" />
                            </mat-form-field>   

                            <mat-form-field floatLabel="always" appearance="outline" style="max-width: 8rem;">
                                <mat-label>{{ "openingHours.openTill" | translate }}</mat-label>
                                <input matInput type="time" [formControl]="segment.timeTill" />
                            </mat-form-field>   

                            <button mat-icon-button aria-label="Clear" *ngIf="day.enabled.enabled"  (click)="removeSegment(day, segment)">
                                <mat-icon svgIcon="close"></mat-icon>
                            </button>

                            <button mat-icon-button aria-label="Add" *ngIf="day.enabled.enabled && day.segments.length < 2" (click)="addSegment(day)">
                                <mat-icon svgIcon="add"></mat-icon>
                            </button>
                        </div>

                    </div>
                </div>
            </div>

        </div>
        <mat-hint>{{ "hints.published" | translate }}</mat-hint>
    </div>

</div>


