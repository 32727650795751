
<div class="su-container">
    <div class="grid md:grid-cols-5 grid-cols-1 md:gap-4 gap-12 md:py-40 py-20 text-center">

        <div class="md:col-start-1 md:col-end-3 md:text-left text-center ">
            <h1 class="text-2xl md:text-4xl text-gray-800">{{ "login.title" | translate }}</h1>
            <p class="mt-4 text-gray-600">{{ "login.text" | translate }}</p>
            <a class="btn mt-6 py-3 px-5 md:inline-block md:px-12" (click)="handleLogIn()">{{ "login.button" | translate }}</a>
        </div>

        <div class="md:col-end-6 md:col-span-2 flex justify-center">
            <img src="/assets/images/login-banner.jpg" alt="Illustration" class="rounded">
        </div>

    </div>
</div>