<app-sticky-element>
    <div class="su-header">
        <div class="su-container">
            <div class="flex">
                <!-- logo -->
                <a [routerLink]="'/'" 
                    [ariaLabel]="'GoldenPages' | translate"
                    class="flex md:flex-col md:items-center items-baseline">
                    <app-icon [icon]="'logo_symbol_border'" svgClass="logo"></app-icon>
                    <app-icon [icon]="'logo_txt_be_nl'" svgClass="logo-text" *ngIf="translate.currentLang == 'be_nl'"></app-icon>
                    <app-icon [icon]="'logo_txt_be_en'" svgClass="logo-text" *ngIf="translate.currentLang == 'be_en'"></app-icon>
                    <app-icon [icon]="'logo_txt_be_fr'" svgClass="logo-text" *ngIf="translate.currentLang == 'be_fr'"></app-icon>
                </a>

                <!-- form -->
                <div class="flex gap-2 ml-auto">
                    <!-- links -->
                    <div class="flex gap-4 items-center">
                        <a *ngIf="signOutButtonEnabled"
                            (click)="signOut()" 
                            class="btn btn--large login-btn">{{ "header.signOut" | translate }}</a>

                        <ul [ngClass]="{'hidden': !isLoginPage}"
                            class="md:block">
                            <app-language-switch></app-language-switch>
                        </ul>
                    </div>
                </div>
            </div>
        </div><!-- /container -->
    </div>
</app-sticky-element>