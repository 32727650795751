import { sendEvent } from './request';
/**
 * Initializes the tracker with your default values.
 *
 * ### Example (es module)
 * ```js
 * import Plausible from 'plausible-tracker'
 *
 * const { enableAutoPageviews, trackEvent } = Plausible({
 *   domain: 'my-app-domain.com',
 *   hashMode: true
 * })
 *
 * enableAutoPageviews()
 *
 * function onUserRegister() {
 *   trackEvent('register')
 * }
 * ```
 *
 * ### Example (commonjs)
 * ```js
 * var Plausible = require('plausible-tracker');
 *
 * var { enableAutoPageviews, trackEvent } = Plausible({
 *   domain: 'my-app-domain.com',
 *   hashMode: true
 * })
 *
 * enableAutoPageviews()
 *
 * function onUserRegister() {
 *   trackEvent('register')
 * }
 * ```
 *
 * @param defaults - Default event parameters that will be applied to all requests.
 */
export default function Plausible(defaults) {
  const getConfig = () => ({
    hashMode: false,
    trackLocalhost: false,
    url: location.href,
    domain: location.hostname,
    referrer: document.referrer || null,
    deviceWidth: window.innerWidth,
    apiHost: 'https://plausible.io',
    ...defaults
  });
  const trackEvent = (eventName, options, eventData) => {
    sendEvent(eventName, {
      ...getConfig(),
      ...eventData
    }, options);
  };
  const trackPageview = (eventData, options) => {
    trackEvent('pageview', options, eventData);
  };
  const enableAutoPageviews = () => {
    const page = () => trackPageview();
    // Attach pushState and popState listeners
    const originalPushState = history.pushState;
    if (originalPushState) {
      // eslint-disable-next-line functional/immutable-data
      history.pushState = function (data, title, url) {
        originalPushState.apply(this, [data, title, url]);
        page();
      };
      addEventListener('popstate', page);
    }
    // Attach hashchange listener
    if (defaults && defaults.hashMode) {
      addEventListener('hashchange', page);
    }
    // Trigger first page view
    trackPageview();
    return function cleanup() {
      if (originalPushState) {
        // eslint-disable-next-line functional/immutable-data
        history.pushState = originalPushState;
        removeEventListener('popstate', page);
      }
      if (defaults && defaults.hashMode) {
        removeEventListener('hashchange', page);
      }
    };
  };
  const enableAutoOutboundTracking = (targetNode = document, observerInit = {
    subtree: true,
    childList: true,
    attributes: true,
    attributeFilter: ['href']
  }) => {
    function trackClick(event) {
      trackEvent('Outbound Link: Click', {
        props: {
          url: this.href
        }
      });
      /* istanbul ignore next */
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!(typeof process !== 'undefined' && process && process.env.NODE_ENV === 'test')) {
        setTimeout(() => {
          // eslint-disable-next-line functional/immutable-data
          location.href = this.href;
        }, 150);
      }
      event.preventDefault();
    }
    // eslint-disable-next-line functional/prefer-readonly-type
    const tracked = new Set();
    function addNode(node) {
      if (node instanceof HTMLAnchorElement) {
        if (node.host !== location.host) {
          node.addEventListener('click', trackClick);
          tracked.add(node);
        }
      } /* istanbul ignore next */else if ('querySelectorAll' in node) {
        node.querySelectorAll('a').forEach(addNode);
      }
    }
    function removeNode(node) {
      if (node instanceof HTMLAnchorElement) {
        node.removeEventListener('click', trackClick);
        tracked.delete(node);
      } /* istanbul ignore next */else if ('querySelectorAll' in node) {
        node.querySelectorAll('a').forEach(removeNode);
      }
    }
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'attributes') {
          // Handle changed href
          removeNode(mutation.target);
          addNode(mutation.target);
        } /* istanbul ignore next */else if (mutation.type === 'childList') {
          // Handle added nodes
          mutation.addedNodes.forEach(addNode);
          // Handle removed nodes
          mutation.removedNodes.forEach(removeNode);
        }
      });
    });
    // Track existing nodes
    targetNode.querySelectorAll('a').forEach(addNode);
    // Observe mutations
    observer.observe(targetNode, observerInit);
    return function cleanup() {
      tracked.forEach(a => {
        a.removeEventListener('click', trackClick);
      });
      tracked.clear();
      observer.disconnect();
    };
  };
  return {
    trackEvent,
    trackPageview,
    enableAutoPageviews,
    enableAutoOutboundTracking
  };
}
