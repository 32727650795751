export const COUNTRY_UNDEFINED = {
  "name": "Undefined",
  "code": "XX",
  "diallingCode": "+",
}

export const COUNTRIES = [
  {
    "name": "Afghanistan",
    "name_fr": "Afghanistan",
    "name_nl": "Afghanistan",
    "code": "AF",
    "diallingCode": "+93"
  },
  {
    "name": "Albania",
    "name_fr": "Albanie",
    "name_nl": "Albanië",
    "code": "AL",
    "diallingCode": "+355"
  },
  {
    "name": "Algeria",
    "name_fr": "Algérie",
    "name_nl": "Algerije",
    "code": "DZ",
    "diallingCode": "+213"
  },
  {
    "name": "American Samoa",
    "name_fr": "Samoa américaines",
    "name_nl": "Amerikaans-Samoa",
    "code": "AS",
    "diallingCode": "+1"
  },
  {
    "name": "Andorra",
    "name_fr": "Andorre",
    "name_nl": "Andorra",
    "code": "AD",
    "diallingCode": "+376"
  },
  {
    "name": "Angola",
    "name_fr": "Angola",
    "name_nl": "Angola",
    "code": "AO",
    "diallingCode": "+244"
  },
  {
    "name": "Anguilla",
    "name_fr": "Anguilla",
    "name_nl": "Anguilla",
    "code": "AI",
    "diallingCode": "+43"
  },
  {
    "name": "Antigua and Barbuda",
    "name_fr": "Antigua-et-Barbuda",
    "name_nl": "Antigua en Barbuda",
    "code": "AG",
    "diallingCode": "+1"
  },
  {
    "name": "Argentina",
    "name_fr": "Argentine",
    "name_nl": "Argentinië",
    "code": "AR",
    "diallingCode": "+54"
  },
  {
    "name": "Armenia",
    "name_fr": "Arménie",
    "name_nl": "Armenië",
    "code": "AM",
    "diallingCode": "+374"
  },
  {
    "name": "Aruba",
    "name_fr": "Aruba",
    "name_nl": "Aruba",
    "code": "AW",
    "diallingCode": "+297"
  },
  {
    "name": "Australia",
    "name_fr": "Australie",
    "name_nl": "Australië",
    "code": "AU",
    "diallingCode": "+61"
  },
  {
    "name": "Azerbaijan",
    "name_fr": "Azerbaïdjan",
    "name_nl": "Azerbeidzjan",
    "code": "AZ",
    "diallingCode": "+994"
  },
  {
    "name": "Bahamas",
    "name_fr": "Bahamas",
    "name_nl": "Bahamas",
    "code": "BS",
    "diallingCode": "+1"
  },
  {
    "name": "Bahrain",
    "name_fr": "Bahreïn",
    "name_nl": "Bahrein",
    "code": "BH",
    "diallingCode": "+973"
  },
  {
    "name": "Bangladesh",
    "name_fr": "Bangladesh",
    "name_nl": "Bangladesh",
    "code": "BD",
    "diallingCode": "+880"
  },
  {
    "name": "Barbados",
    "name_fr": "Barbade",
    "name_nl": "Barbados",
    "code": "BB",
    "diallingCode": "+1"
  },
  {
    "name": "Belarus",
    "name_fr": "Biélorussie",
    "name_nl": "Wit-Rusland",
    "code": "BY",
    "diallingCode": "+375"
  },
  {
    "name": "Belgium",
    "name_fr": "Belgique",
    "name_nl": "België",
    "code": "BE",
    "diallingCode": "+32"
  },
  {
    "name": "Belize",
    "name_fr": "Belize",
    "name_nl": "Belize",
    "code": "BZ",
    "diallingCode": "+501"
  },
  {
    "name": "Benin",
    "name_fr": "Bénin",
    "name_nl": "Benin",
    "code": "BJ",
    "diallingCode": "+229"
  },
  {
    "name": "Bermuda",
    "name_fr": "Bermudes",
    "name_nl": "Bermuda",
    "code": "BM",
    "diallingCode": "+1"
  },
  {
    "name": "Bhutan",
    "name_fr": "Bhoutan",
    "name_nl": "Bhutan",
    "code": "BT",
    "diallingCode": "+975"
  },
  {
    "name": "Bolivia (Plurinational State of)",
    "name_fr": "Bolivie (État plurinational de)",
    "name_nl": "Bolivia (Plurinationale Staat)",
    "code": "BO",
    "diallingCode": "+591"
  },
  {
    "name": "Bosnia and Herzegovina",
    "name_fr": "Bosnie-Herzégovine",
    "name_nl": "Bosnië-Herzegovina",
    "code": "BA",
    "diallingCode": "+387"
  },
  {
    "name": "Botswana",
    "name_fr": "Botswana",
    "name_nl": "Botswana",
    "code": "BW",
    "diallingCode": "+267"
  },
  {
    "name": "Brazil",
    "name_fr": "Brésil",
    "name_nl": "Brazilië",
    "code": "BR",
    "diallingCode": "+55"
  },
  {
    "name": "British Indian Ocean Territory",
    "name_fr": "Territoire britannique de l'océan Indien",
    "name_nl": "Brits Indische Oceaanterritorium",
    "code": "IO",
    "diallingCode": "+246"
  },
  {
    "name": "Virgin Islands (British)",
    "name_fr": "Îles Vierges britanniques",
    "name_nl": "Maagdeneilanden (Brits)",
    "code": "VG",
    "diallingCode": "+1"
  },
  {
    "name": "Virgin Islands (U.S.)",
    "name_fr": "Îles Vierges (États-Unis)",
    "name_nl": "Maagdeneilanden (VS)",
    "code": "VI",
    "diallingCode": "+1"
  },
  {
    "name": "Brunei Darussalam",
    "name_fr": "Brunei Darussalam",
    "name_nl": "Brunei Darussalam",
    "code": "BN",
    "diallingCode": "+673"
  },
  {
    "name": "Bulgaria",
    "name_fr": "Bulgarie",
    "name_nl": "Bulgarije",
    "code": "BG",
    "diallingCode": "+359"
  },
  {
    "name": "Burkina Faso",
    "name_fr": "Burkina Faso",
    "name_nl": "Burkina Faso",
    "code": "BF",
    "diallingCode": "+226"
  },
  {
    "name": "Burundi",
    "name_fr": "Burundi",
    "name_nl": "Burundi",
    "code": "BI",
    "diallingCode": "+257"
  },
  {
    "name": "Cambodia",
    "name_fr": "Cambodge",
    "name_nl": "Cambodja",
    "code": "KH",
    "diallingCode": "+855"
  },
  {
    "name": "Cameroon",
    "name_fr": "Cameroun",
    "name_nl": "Kameroen",
    "code": "CM",
    "diallingCode": "+237"
  },
  {
    "name": "Canada",
    "name_fr": "Canada",
    "name_nl": "Canada",
    "code": "CA",
    "diallingCode": "+1"
  },
  {
    "name": "Cabo Verde",
    "name_fr": "Cabo Verde",
    "name_nl": "Kaapverdië",
    "code": "CV",
    "diallingCode": "+238"
  },
  {
    "name": "Cayman Islands",
    "name_fr": "Îles Caïmanes",
    "name_nl": "Caymaneilanden",
    "code": "KY",
    "diallingCode": "+1"
  },
  {
    "name": "Central African Republic",
    "name_fr": "République centrafricaine",
    "name_nl": "Centraal-Afrikaanse Republiek",
    "code": "CF",
    "diallingCode": "+236"
  },
  {
    "name": "Chile",
    "name_fr": "Chili",
    "name_nl": "Chili",
    "code": "CL",
    "diallingCode": "+56"
  },
  {
    "name": "China",
    "name_fr": "Chine",
    "name_nl": "China",
    "code": "CN",
    "diallingCode": "+86"
  },
  {
    "name": "Colombia",
    "name_fr": "Colombie",
    "name_nl": "Colombia",
    "code": "CO",
    "diallingCode": "+57"
  },
  {
    "name": "Comoros",
    "name_fr": "Comores",
    "name_nl": "Comoren",
    "code": "KM",
    "diallingCode": "+269"
  },
  {
    "name": "Congo",
    "name_fr": "Congo",
    "name_nl": "Congo",
    "code": "CG",
    "diallingCode": "+242"
  },
  {
    "name": "Congo (Democratic Republic of the)",
    "name_fr": "Congo (République démocratique du)",
    "name_nl": "Congo (Democratische Republiek Congo)",
    "code": "CD",
    "diallingCode": "+243"
  },
  {
    "name": "Cook Islands",
    "name_fr": "Îles Cook",
    "name_nl": "Cookeilanden",
    "code": "CK",
    "diallingCode": "+682"
  },
  {
    "name": "Costa Rica",
    "name_fr": "Costa Rica",
    "name_nl": "Costa Rica",
    "code": "CR",
    "diallingCode": "+506"
  },
  {
    "name": "Croatia",
    "name_fr": "Croatie (Hrvatska)",
    "name_nl": "Kroatië",
    "code": "HR",
    "diallingCode": "+385"
  },
  {
    "name": "Cuba",
    "name_fr": "Cuba",
    "name_nl": "Cuba",
    "code": "CU",
    "diallingCode": "+53"
  },
  {
    "name": "Cyprus",
    "name_fr": "Chypre",
    "name_nl": "Cyprus",
    "code": "CY",
    "diallingCode": "+357"
  },
  {
    "name": "Czech Republic",
    "name_fr": "République tchèque",
    "name_nl": "Tsjechische Republiek",
    "code": "CZ",
    "diallingCode": "+420"
  },
  {
    "name": "Denmark",
    "name_fr": "Danemark",
    "name_nl": "Denemarken",
    "code": "DK",
    "diallingCode": "+45"
  },
  {
    "name": "Djibouti",
    "name_fr": "Djibouti",
    "name_nl": "Djibouti",
    "code": "DJ",
    "diallingCode": "+253"
  },
  {
    "name": "Dominica",
    "name_fr": "Dominique",
    "name_nl": "Dominica",
    "code": "DM",
    "diallingCode": "+1"
  },
  {
    "name": "Dominican Republic",
    "name_fr": "République dominicaine",
    "name_nl": "Dominicaanse Republiek",
    "code": "DO",
    "diallingCode": "+1"
  },
  {
    "name": "Ecuador",
    "name_fr": "Équateur",
    "name_nl": "Ecuador",
    "code": "EC",
    "diallingCode": "+593"
  },
  {
    "name": "Egypt",
    "name_fr": "Égypte",
    "name_nl": "Egypte",
    "code": "EG",
    "diallingCode": "+20"
  },
  {
    "name": "El Salvador",
    "name_fr": "Salvador",
    "name_nl": "El Salvador",
    "code": "SV",
    "diallingCode": "+503"
  },
  {
    "name": "Equatorial Guinea",
    "name_fr": "Guinée équatoriale",
    "name_nl": "Equatoriaal-Guinea",
    "code": "GQ",
    "diallingCode": "+240"
  },
  {
    "name": "Eritrea",
    "name_fr": "Érythrée",
    "name_nl": "Eritrea",
    "code": "ER",
    "diallingCode": "+291"
  },
  {
    "name": "Estonia",
    "name_fr": "Estonie",
    "name_nl": "Estland",
    "code": "EE",
    "diallingCode": "+372"
  },
  {
    "name": "Ethiopia",
    "name_fr": "Éthiopie",
    "name_nl": "Ethiopië",
    "code": "ET",
    "diallingCode": "+251"
  },
  {
    "name": "Falkland Islands (Malvinas)",
    "name_fr": "Îles Falkland (Malvinas)",
    "name_nl": "Falklandeilanden (Malvinas)",
    "code": "FK",
    "diallingCode": "+500"
  },
  {
    "name": "Faroe Islands",
    "name_fr": "Féroé (îles)",
    "name_nl": "Faeröer",
    "code": "FO",
    "diallingCode": "+298"
  },
  {
    "name": "Fiji",
    "name_fr": "Fidji",
    "name_nl": "Fiji",
    "code": "FJ",
    "diallingCode": "+679"
  },
  {
    "name": "Finland",
    "name_fr": "Finlande",
    "name_nl": "Finland",
    "code": "FI",
    "diallingCode": "+358"
  },
  {
    "name": "France",
    "name_fr": "France",
    "name_nl": "Frankrijk",
    "code": "FR",
    "diallingCode": "+33"
  },
  {
    "name": "French Guiana",
    "name_fr": "Guyane française",
    "name_nl": "Frans-Guyana",
    "code": "GF",
    "diallingCode": "+594"
  },
  {
    "name": "French Polynesia",
    "name_fr": "Polynésie française",
    "name_nl": "Frans-Polynesië",
    "code": "PF",
    "diallingCode": "+689"
  },
  {
    "name": "Gabon",
    "name_fr": "Gabon",
    "name_nl": "Gabon",
    "code": "GA",
    "diallingCode": "+241"
  },
  {
    "name": "Gambia",
    "name_fr": "Gambie",
    "name_nl": "Gambia",
    "code": "GM",
    "diallingCode": "+220"
  },
  {
    "name": "Georgia",
    "name_fr": "Géorgie",
    "name_nl": "Georgië",
    "code": "GE",
    "diallingCode": "+995"
  },
  {
    "name": "Germany",
    "name_fr": "Allemagne",
    "name_nl": "Duitsland",
    "code": "DE",
    "diallingCode": "+49"
  },
  {
    "name": "Ghana",
    "name_fr": "Ghana",
    "name_nl": "Ghana",
    "code": "GH",
    "diallingCode": "+233"
  },
  {
    "name": "Gibraltar",
    "name_fr": "Gibraltar",
    "name_nl": "Gibraltar",
    "code": "GI",
    "diallingCode": "+350"
  },
  {
    "name": "Greece",
    "name_fr": "Grèce",
    "name_nl": "Griekenland",
    "code": "GR",
    "diallingCode": "+30"
  },
  {
    "name": "Greenland",
    "name_fr": "Groenland",
    "name_nl": "Groenland",
    "code": "GL",
    "diallingCode": "+299"
  },
  {
    "name": "Grenada",
    "name_fr": "Grenade",
    "name_nl": "Grenada",
    "code": "GD",
    "diallingCode": "+1"
  },
  {
    "name": "Guadeloupe",
    "name_fr": "Guadeloupe",
    "name_nl": "Guadeloupe",
    "code": "GP",
    "diallingCode": "+590"
  },
  {
    "name": "Guam",
    "name_fr": "Guam",
    "name_nl": "Guam",
    "code": "GU",
    "diallingCode": "+1"
  },
  {
    "name": "Guatemala",
    "name_fr": "Guatemala",
    "name_nl": "Guatemala",
    "code": "GT",
    "diallingCode": "+502"
  },
  {
    "name": "Guinea",
    "name_fr": "Guinée",
    "name_nl": "Guinee",
    "code": "GN",
    "diallingCode": "+224"
  },
  {
    "name": "Guinea-Bissau",
    "name_fr": "Guinée-Bissau",
    "name_nl": "Guinee-Bissau",
    "code": "GW",
    "diallingCode": "+245"
  },
  {
    "name": "Guyana",
    "name_fr": "Guyana",
    "name_nl": "Guyana",
    "code": "GY",
    "diallingCode": "+592"
  },
  {
    "name": "Haiti",
    "name_fr": "Haïti",
    "name_nl": "Haïti",
    "code": "HT",
    "diallingCode": "+509"
  },
  {
    "name": "Holy See",
    "name_fr": "Saint-Siège",
    "name_nl": "Heilige Stoel",
    "code": "VA",
    "diallingCode": "+39"
  },
  {
    "name": "Honduras",
    "name_fr": "Honduras",
    "name_nl": "Honduras",
    "code": "HN",
    "diallingCode": "+504"
  },
  {
    "name": "Hong Kong",
    "name_fr": "Hong Kong",
    "name_nl": "Hongkong",
    "code": "HK",
    "diallingCode": "+852"
  },
  {
    "name": "Hungary",
    "name_fr": "Hongrie",
    "name_nl": "Hongarije",
    "code": "HU",
    "diallingCode": "+36"
  },
  {
    "name": "Iceland",
    "name_fr": "Islande",
    "name_nl": "IJsland",
    "code": "IS",
    "diallingCode": "+354"
  },
  {
    "name": "India",
    "name_fr": "Inde",
    "name_nl": "India",
    "code": "IN",
    "diallingCode": "+91"
  },
  {
    "name": "Indonesia",
    "name_fr": "Indonésie",
    "name_nl": "Indonesië",
    "code": "ID",
    "diallingCode": "+62"
  },
  {
    "name": "Côte d'Ivoire",
    "name_fr": "Côte d'Ivoire",
    "name_nl": "Ivoorkust",
    "code": "CI",
    "diallingCode": "+225"
  },
  {
    "name": "Iran (Islamic Republic of)",
    "name_fr": "Iran (République islamique d')",
    "name_nl": "Iran (Islamitische Republiek)",
    "code": "IR",
    "diallingCode": "+98"
  },
  {
    "name": "Iraq",
    "name_fr": "Irak",
    "name_nl": "Irak",
    "code": "IQ",
    "diallingCode": "+964"
  },
  {
    "name": "Ireland",
    "name_fr": "Irlande",
    "name_nl": "Ierland",
    "code": "IE",
    "diallingCode": "+353"
  },
  {
    "name": "Israel",
    "name_fr": "Israël",
    "name_nl": "Israël",
    "code": "IL",
    "diallingCode": "+972"
  },
  {
    "name": "Italy",
    "name_fr": "Italie",
    "name_nl": "Italië",
    "code": "IT",
    "diallingCode": "+39"
  },
  {
    "name": "Jamaica",
    "name_fr": "Jamaïque",
    "name_nl": "Jamaica",
    "code": "JM",
    "diallingCode": "+1"
  },
  {
    "name": "Japan",
    "name_fr": "Japon",
    "name_nl": "Japan",
    "code": "JP",
    "diallingCode": "+81"
  },
  {
    "name": "Jordan",
    "name_fr": "Jordanie",
    "name_nl": "Jordanië",
    "code": "JO",
    "diallingCode": "+962"
  },
  {
    "name": "Kazakhstan",
    "name_fr": "Kazakhstan",
    "name_nl": "Kazachstan",
    "code": "KZ",
    "diallingCode": "+7"
  },
  {
    "name": "Kenya",
    "name_fr": "Kenya",
    "name_nl": "Kenia",
    "code": "KE",
    "diallingCode": "+254"
  },
  {
    "name": "Kiribati",
    "name_fr": "Kiribati",
    "name_nl": "Kiribati",
    "code": "KI",
    "diallingCode": "+686"
  },
  {
    "name": "Kuwait",
    "name_fr": "Koweït",
    "name_nl": "Koeweit",
    "code": "KW",
    "diallingCode": "+965"
  },
  {
    "name": "Kyrgyzstan",
    "name_fr": "Kirghizistan",
    "name_nl": "Kirgizië",
    "code": "KG",
    "diallingCode": "+996"
  },
  {
    "name": "Lao People's Democratic Republic",
    "name_fr": "République démocratique populaire lao",
    "name_nl": "Democratische Volksrepubliek Laos",
    "code": "LA",
    "diallingCode": "+856"
  },
  {
    "name": "Latvia",
    "name_fr": "Lettonie",
    "name_nl": "Letland",
    "code": "LV",
    "diallingCode": "+371"
  },
  {
    "name": "Lebanon",
    "name_fr": "Liban",
    "name_nl": "Libanon",
    "code": "LB",
    "diallingCode": "+961"
  },
  {
    "name": "Lesotho",
    "name_fr": "Lesotho",
    "name_nl": "Lesotho",
    "code": "LS",
    "diallingCode": "+266"
  },
  {
    "name": "Liberia",
    "name_fr": "Libéria",
    "name_nl": "Liberia",
    "code": "LR",
    "diallingCode": "+231"
  },
  {
    "name": "Libya",
    "name_fr": "Libye",
    "name_nl": "Libië",
    "code": "LY",
    "diallingCode": "+218"
  },
  {
    "name": "Liechtenstein",
    "name_fr": "Liechtenstein",
    "name_nl": "Liechtenstein",
    "code": "LI",
    "diallingCode": "+423"
  },
  {
    "name": "Lithuania",
    "name_fr": "Lituanie",
    "name_nl": "Litouwen",
    "code": "LT",
    "diallingCode": "+370"
  },
  {
    "name": "Luxembourg",
    "name_fr": "Luxembourg",
    "name_nl": "Luxemburg",
    "code": "LU",
    "diallingCode": "+352"
  },
  {
    "name": "Macao",
    "name_fr": "Macao",
    "name_nl": "Macao",
    "code": "MO",
    "diallingCode": "+853"
  },
  {
    "name": "Macedonia (the former Yugoslav Republic of)",
    "name_fr": "Macédoine (ex-République yougoslave du)",
    "name_nl": "Macedonië (de voormalige Joegoslavische Republiek)",
    "code": "MK",
    "diallingCode": "+389"
  },
  {
    "name": "Madagascar",
    "name_fr": "Madagascar",
    "name_nl": "Madagaskar",
    "code": "MG",
    "diallingCode": "+261"
  },
  {
    "name": "Malawi",
    "name_fr": "Malawi",
    "name_nl": "Malawi",
    "code": "MW",
    "diallingCode": "+265"
  },
  {
    "name": "Malaysia",
    "name_fr": "Malaisie",
    "name_nl": "Maleisië",
    "code": "MY",
    "diallingCode": "+60"
  },
  {
    "name": "Maldives",
    "name_fr": "Maldives",
    "name_nl": "Maldiven",
    "code": "MV",
    "diallingCode": "+960"
  },
  {
    "name": "Mali",
    "name_fr": "Mali",
    "name_nl": "Mali",
    "code": "ML",
    "diallingCode": "+223"
  },
  {
    "name": "Malta",
    "name_fr": "Malte",
    "name_nl": "Malta",
    "code": "MT",
    "diallingCode": "+356"
  },
  {
    "name": "Marshall Islands",
    "name_fr": "Îles Marshall",
    "name_nl": "Marshalleilanden",
    "code": "MH",
    "diallingCode": "+692"
  },
  {
    "name": "Martinique",
    "name_fr": "Martinique",
    "name_nl": "Martinique",
    "code": "MQ",
    "diallingCode": "+596"
  },
  {
    "name": "Mauritania",
    "name_fr": "Mauritanie",
    "name_nl": "Mauritanië",
    "code": "MR",
    "diallingCode": "+222"
  },
  {
    "name": "Mauritius",
    "name_fr": "Maurice",
    "name_nl": "Mauritius",
    "code": "MU",
    "diallingCode": "+230"
  },
  {
    "name": "Mayotte",
    "name_fr": "Mayotte",
    "name_nl": "Mayotte",
    "code": "YT",
    "diallingCode": "+262"
  },
  {
    "name": "Mexico",
    "name_fr": "Mexique",
    "name_nl": "Mexico",
    "code": "MX",
    "diallingCode": "+52"
  },
  {
    "name": "Micronesia (Federated States of)",
    "name_fr": "Micronésie (États fédérés de)",
    "name_nl": "Micronesië (Federale Staten van)",
    "code": "FM",
    "diallingCode": "+691"
  },
  {
    "name": "Moldova (Republic of)",
    "name_fr": "Moldavie (République de)",
    "name_nl": "Moldavië (Republiek Moldavië)",
    "code": "MD",
    "diallingCode": "+373"
  },
  {
    "name": "Monaco",
    "name_fr": "Monaco",
    "name_nl": "Monaco",
    "code": "MC",
    "diallingCode": "+377"
  },
  {
    "name": "Mongolia",
    "name_fr": "Mongolie",
    "name_nl": "Mongolië",
    "code": "MN",
    "diallingCode": "+976"
  },
  {
    "name": "Montenegro",
    "name_fr": "Monténégro",
    "name_nl": "Montenegro",
    "code": "ME",
    "diallingCode": "+382"
  },
  {
    "name": "Montserrat",
    "name_fr": "Montserrat",
    "name_nl": "Montserrat",
    "code": "MS",
    "diallingCode": "+1"
  },
  {
    "name": "Morocco",
    "name_fr": "Maroc",
    "name_nl": "Marokko",
    "code": "MA",
    "diallingCode": "+212"
  },
  {
    "name": "Mozambique",
    "name_fr": "Mozambique",
    "name_nl": "Mozambique",
    "code": "MZ",
    "diallingCode": "+258"
  },
  {
    "name": "Myanmar",
    "name_fr": "Myanmar",
    "name_nl": "Myanmar",
    "code": "MM",
    "diallingCode": "+95"
  },
  {
    "name": "Namibia",
    "name_fr": "Namibie",
    "name_nl": "Namibië",
    "code": "NA",
    "diallingCode": "+264"
  },
  {
    "name": "Nauru",
    "name_fr": "Nauru",
    "name_nl": "Nauru",
    "code": "NR",
    "diallingCode": "+674"
  },
  {
    "name": "Nepal",
    "name_fr": "Népal",
    "name_nl": "Nepal",
    "code": "NP",
    "diallingCode": "+977"
  },
  {
    "name": "Netherlands",
    "name_fr": "Pays-Bas",
    "name_nl": "Nederland",
    "code": "NL",
    "diallingCode": "+31"
  },
  {
    "name": "New Caledonia",
    "name_fr": "Nouvelle-Calédonie",
    "name_nl": "Nieuw-Caledonië",
    "code": "NC",
    "diallingCode": "+687"
  },
  {
    "name": "New Zealand",
    "name_fr": "Nouvelle-Zélande",
    "name_nl": "Nieuw-Zeeland",
    "code": "NZ",
    "diallingCode": "+64"
  },
  {
    "name": "Nicaragua",
    "name_fr": "Nicaragua",
    "name_nl": "Nicaragua",
    "code": "NI",
    "diallingCode": "+505"
  },
  {
    "name": "Niger",
    "name_fr": "Niger",
    "name_nl": "Niger",
    "code": "NE",
    "diallingCode": "+227"
  },
  {
    "name": "Nigeria",
    "name_fr": "Nigéria",
    "name_nl": "Nigeria",
    "code": "NG",
    "diallingCode": "+234"
  },
  {
    "name": "Niue",
    "name_fr": "Nioué",
    "name_nl": "Niue",
    "code": "NU",
    "diallingCode": "+683"
  },
  {
    "name": "Norfolk Island",
    "name_fr": "Île Norfolk",
    "name_nl": "Norfolk",
    "code": "NF",
    "diallingCode": "+672"
  },
  {
    "name": "Korea (Democratic People's Republic of)",
    "name_fr": "Corée (République populaire démocratique du)",
    "name_nl": "Korea (Democratische Volksrepubliek)",
    "code": "KP",
    "diallingCode": "+850"
  },
  {
    "name": "Northern Mariana Islands",
    "name_fr": "Îles Mariannes du Nord",
    "name_nl": "Noordelijke Marianen",
    "code": "MP",
    "diallingCode": "+1"
  },
  {
    "name": "Norway",
    "name_fr": "Norvège",
    "name_nl": "Noorwegen",
    "code": "NO",
    "diallingCode": "+47"
  },
  {
    "name": "Oman",
    "name_fr": "Oman",
    "name_nl": "Oman",
    "code": "OM",
    "diallingCode": "+968"
  },
  {
    "name": "Pakistan",
    "name_fr": "Pakistan",
    "name_nl": "Pakistan",
    "code": "PK",
    "diallingCode": "+92"
  },
  {
    "name": "Palau",
    "name_fr": "Palaos",
    "name_nl": "Palau",
    "code": "PW",
    "diallingCode": "+680"
  },
  {
    "name": "Palestine, State of",
    "name_fr": "Palestine, État de",
    "name_nl": "Palestina, Staat van",
    "code": "PS",
    "diallingCode": "+970"
  },
  {
    "name": "Panama",
    "name_fr": "Panama",
    "name_nl": "Panama",
    "code": "PA",
    "diallingCode": "+507"
  },
  {
    "name": "Papua New Guinea",
    "name_fr": "Papouasie-Nouvelle-Guinée",
    "name_nl": "Papoea-Nieuw-Guinea",
    "code": "PG",
    "diallingCode": "+675"
  },
  {
    "name": "Paraguay",
    "name_fr": "Paraguay",
    "name_nl": "Paraguay",
    "code": "PY",
    "diallingCode": "+595"
  },
  {
    "name": "Peru",
    "name_fr": "Pérou",
    "name_nl": "Peru",
    "code": "PE",
    "diallingCode": "+51"
  },
  {
    "name": "Philippines",
    "name_fr": "Philippines",
    "name_nl": "Filipijnen",
    "code": "PH",
    "diallingCode": "+63"
  },
  {
    "name": "Poland",
    "name_fr": "Pologne",
    "name_nl": "Polen",
    "code": "PL",
    "diallingCode": "+48"
  },
  {
    "name": "Portugal",
    "name_fr": "Portugal",
    "name_nl": "Portugal",
    "code": "PT",
    "diallingCode": "+351"
  },
  {
    "name": "Puerto Rico",
    "name_fr": "Porto Rico",
    "name_nl": "Puerto Rico",
    "code": "PR",
    "diallingCode": "+1"
  },
  {
    "name": "Qatar",
    "name_fr": "Qatar",
    "name_nl": "Qatar",
    "code": "QA",
    "diallingCode": "+974"
  },
  {
    "name": "Republic of Kosovo",
    "name_fr": "République du Kosovo",
    "name_nl": "Republiek Kosovo",
    "code": "XK",
    "diallingCode": "+381"
  },
  {
    "name": "Réunion",
    "name_fr": "Réunion",
    "name_nl": "Réunion",
    "code": "RE",
    "diallingCode": "+262"
  },
  {
    "name": "Romania",
    "name_fr": "Roumanie",
    "name_nl": "Roemenië",
    "code": "RO",
    "diallingCode": "+40"
  },
  {
    "name": "Russian Federation",
    "name_fr": "Fédération de Russie",
    "name_nl": "Rusland",
    "code": "RU",
    "diallingCode": "+7"
  },
  {
    "name": "Rwanda",
    "name_fr": "Rwanda",
    "name_nl": "Rwanda",
    "code": "RW",
    "diallingCode": "+250"
  },
  {
    "name": "Saint Barthélemy",
    "name_fr": "Saint-Barthélemy",
    "name_nl": "Saint Barthélemy",
    "code": "BL",
    "diallingCode": "+590"
  },
  {
    "name": "Saint Helena, Ascension and Tristan da Cunha",
    "name_fr": "Sainte-Hélène, l'Ascension et Tristan da Cunha",
    "name_nl": "Sint-Helena, Hemelvaart en Tristan da Cunha",
    "code": "SH",
    "diallingCode": "+290"
  },
  {
    "name": "Saint Kitts and Nevis",
    "name_fr": "Saint-Christophe-et-Niévès",
    "name_nl": "Saint Kitts en Nevis",
    "code": "KN",
    "diallingCode": "+1"
  },
  {
    "name": "Saint Lucia",
    "name_fr": "Sainte-Lucie",
    "name_nl": "Saint Lucia",
    "code": "LC",
    "diallingCode": "+1"
  },
  {
    "name": "Saint Martin (French part)",
    "name_fr": "Saint-Martin (partie française)",
    "name_nl": "Saint Martin (Frans deel)",
    "code": "MF",
    "diallingCode": "+590"
  },
  {
    "name": "Saint Pierre and Miquelon",
    "name_fr": "Saint-Pierre-et-Miquelon",
    "name_nl": "Saint-Pierre en Miquelon",
    "code": "PM",
    "diallingCode": "+508"
  },
  {
    "name": "Saint Vincent and the Grenadines",
    "name_fr": "Saint-Vincent-et-les-Grenadines",
    "name_nl": "Saint Vincent en de Grenadines",
    "code": "VC",
    "diallingCode": "+1"
  },
  {
    "name": "Samoa",
    "name_fr": "Samoa",
    "name_nl": "Samoa",
    "code": "WS",
    "diallingCode": "+685"
  },
  {
    "name": "San Marino",
    "name_fr": "Saint-Marin",
    "name_nl": "San Marino",
    "code": "SM",
    "diallingCode": "+378"
  },
  {
    "name": "Sao Tome and Principe",
    "name_fr": "Sao Tomé-et-Principe",
    "name_nl": "Sao Tomé en Principe",
    "code": "ST",
    "diallingCode": "+239"
  },
  {
    "name": "Saudi Arabia",
    "name_fr": "Arabie Saoudite",
    "name_nl": "Saudi-Arabië",
    "code": "SA",
    "diallingCode": "+966"
  },
  {
    "name": "Senegal",
    "name_fr": "Sénégal",
    "name_nl": "Senegal",
    "code": "SN",
    "diallingCode": "+221"
  },
  {
    "name": "Serbia",
    "name_fr": "Serbie",
    "name_nl": "Servië",
    "code": "RS",
    "diallingCode": "+381"
  },
  {
    "name": "Seychelles",
    "name_fr": "Seychelles",
    "name_nl": "Seychellen",
    "code": "SC",
    "diallingCode": "+248"
  },
  {
    "name": "Sierra Leone",
    "name_fr": "Sierra Leone",
    "name_nl": "Sierra Leone",
    "code": "SL",
    "diallingCode": "+232"
  },
  {
    "name": "Singapore",
    "name_fr": "Singapour",
    "name_nl": "Singapore",
    "code": "SG",
    "diallingCode": "+65"
  },
  {
    "name": "Singapore",
    "name_fr": "Singapour",
    "name_nl": "Singapore",
    "code": "SG",
    "diallingCode": "+65"
  },
  {
    "name": "Slovakia",
    "name_fr": "Slovaquie",
    "name_nl": "Slowakije",
    "code": "SK",
    "diallingCode": "+421"
  },
  {
    "name": "Slovenia",
    "name_fr": "Slovénie",
    "name_nl": "Slovenië",
    "code": "SI",
    "diallingCode": "+386"
  },
  {
    "name": "Solomon Islands",
    "name_fr": "Îles Salomon",
    "name_nl": "Salomonseilanden",
    "code": "SB",
    "diallingCode": "+677"
  },
  {
    "name": "Somalia",
    "name_fr": "Somalie",
    "name_nl": "Somalië",
    "code": "SO",
    "diallingCode": "+252"
  },
  {
    "name": "South Africa",
    "name_fr": "Afrique du Sud",
    "name_nl": "Zuid-Afrika",
    "code": "ZA",
    "diallingCode": "+27"
  },
  {
    "name": "Korea (Republic of)",
    "name_fr": "Corée (République de)",
    "name_nl": "Korea (Republiek Korea)",
    "code": "KR",
    "diallingCode": "+82"
  },
  {
    "name": "Spain",
    "name_fr": "Espagne",
    "name_nl": "Spanje",
    "code": "ES",
    "diallingCode": "+34"
  },
  {
    "name": "Sri Lanka",
    "name_fr": "Sri Lanka",
    "name_nl": "Sri Lanka",
    "code": "LK",
    "diallingCode": "+94"
  },
  {
    "name": "Sudan",
    "name_fr": "Soudan",
    "name_nl": "Soedan",
    "code": "SD",
    "diallingCode": "+249"
  },
  {
    "name": "Suriname",
    "name_fr": "Suriname",
    "name_nl": "Suriname",
    "code": "SR",
    "diallingCode": "+597"
  },
  {
    "name": "Swaziland",
    "name_fr": "Swaziland",
    "name_nl": "Swaziland",
    "code": "SZ",
    "diallingCode": "+268"
  },
  {
    "name": "Sweden",
    "name_fr": "Suède",
    "name_nl": "Zweden",
    "code": "SE",
    "diallingCode": "+46"
  },
  {
    "name": "Switzerland",
    "name_fr": "Suisse",
    "name_nl": "Zwitserland",
    "code": "CH",
    "diallingCode": "+41"
  },
  {
    "name": "Syrian Arab Republic",
    "name_fr": "République arabe syrienne",
    "name_nl": "Syrische Arabische Republiek",
    "code": "SY",
    "diallingCode": "+963"
  },
  {
    "name": "Taiwan",
    "name_fr": "Taiwan",
    "name_nl": "Taiwan",
    "code": "TW",
    "diallingCode": "+886"
  },
  {
    "name": "Tajikistan",
    "name_fr": "Tadjikistan",
    "name_nl": "Tadzjikistan",
    "code": "TJ",
    "diallingCode": "+992"
  },
  {
    "name": "Tanzania, United Republic of",
    "name_fr": "Tanzanie, République-Unie de",
    "name_nl": "Tanzania, Verenigde Republiek",
    "code": "TZ",
    "diallingCode": "+255"
  },
  {
    "name": "Thailand",
    "name_fr": "Thaïlande",
    "name_nl": "Thailand",
    "code": "TH",
    "diallingCode": "+66"
  },
  {
    "name": "Timor-Leste",
    "name_fr": "Timor-Leste",
    "name_nl": "Oost-Timor",
    "code": "TL",
    "diallingCode": "+670"
  },
  {
    "name": "Togo",
    "name_fr": "Togo",
    "name_nl": "Togo",
    "code": "TG",
    "diallingCode": "+228"
  },
  {
    "name": "Tokelau",
    "name_fr": "Tokélaou",
    "name_nl": "Tokelau",
    "code": "TK",
    "diallingCode": "+690"
  },
  {
    "name": "Tonga",
    "name_fr": "Tonga",
    "name_nl": "Tonga",
    "code": "TO",
    "diallingCode": "+676"
  },
  {
    "name": "Trinidad and Tobago",
    "name_fr": "Trinité-et-Tobago",
    "name_nl": "Trinidad enTobago",
    "code": "TT",
    "diallingCode": "+1"
  },
  {
    "name": "Tunisia",
    "name_fr": "Tunisie",
    "name_nl": "Tunesië",
    "code": "TN",
    "diallingCode": "+216"
  },
  {
    "name": "Turkey",
    "name_fr": "Turquie",
    "name_nl": "Turkije",
    "code": "TR",
    "diallingCode": "+90"
  },
  {
    "name": "Turkmenistan",
    "name_fr": "Turkménistan",
    "name_nl": "Turkmenistan",
    "code": "TM",
    "diallingCode": "+993"
  },
  {
    "name": "Turks and Caicos Islands",
    "name_fr": "Îles Turques-et-Caïques",
    "name_nl": "Turks- en Caicoseilanden",
    "code": "TC",
    "diallingCode": "+1"
  },
  {
    "name": "Tuvalu",
    "name_fr": "Tuvalu",
    "name_nl": "Tuvalu",
    "code": "TV",
    "diallingCode": "+688"
  },
  {
    "name": "Uganda",
    "name_fr": "Ouganda",
    "name_nl": "Oeganda",
    "code": "UG",
    "diallingCode": "+256"
  },
  {
    "name": "Ukraine",
    "name_fr": "Ukraine",
    "name_nl": "Oekraïne",
    "code": "UA",
    "diallingCode": "+380"
  },
  {
    "name": "United Arab Emirates",
    "name_fr": "Émirats arabes unis",
    "name_nl": "Verenigde Arabische Emiraten",
    "code": "AE",
    "diallingCode": "+971"
  },
  {
    "name": "United Kingdom of Great Britain and Northern Ireland",
    "name_fr": "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord",
    "name_nl": "Verenigd Koninkrijk van Groot-Brittannië en Noord-Ierland",
    "code": "GB",
    "diallingCode": "+44"
  },
  {
    "name": "United States of America",
    "name_fr": "États-Unis d'Amérique",
    "name_nl": "Verenigde Staten van Amerika",
    "code": "US",
    "diallingCode": "+1"
  },
  {
    "name": "Uruguay",
    "name_fr": "Uruguay",
    "name_nl": "Uruguay",
    "code": "UY",
    "diallingCode": "+598"
  },
  {
    "name": "Uzbekistan",
    "name_fr": "Ouzbékistan",
    "name_nl": "Oezbekistan",
    "code": "UZ",
    "diallingCode": "+998"
  },
  {
    "name": "Vanuatu",
    "name_fr": "Vanuatu",
    "name_nl": "Vanuatu",
    "code": "VU",
    "diallingCode": "+678"
  },
  {
    "name": "Venezuela (Bolivarian Republic of)",
    "name_fr": "Venezuela (République bolivarienne du)",
    "name_nl": "Venezuela (Bolivariaanse Republiek)",
    "code": "VE",
    "diallingCode": "+58"
  },
  {
    "name": "Viet Nam",
    "name_fr": "Vietnam",
    "name_nl": "Vietnam",
    "code": "VN",
    "diallingCode": "+84"
  },
  {
    "name": "Wallis and Futuna",
    "name_fr": "Wallis-et-Futuna",
    "name_nl": "Wallis en Futuna",
    "code": "WF",
    "diallingCode": "+681"
  },
  {
    "name": "Yemen",
    "name_fr": "Yémen",
    "name_nl": "Jemen",
    "code": "YE",
    "diallingCode": "+967"
  },
  {
    "name": "Zambia",
    "name_fr": "Zambie",
    "name_nl": "Zambia",
    "code": "ZM",
    "diallingCode": "+260"
  },
  {
    "name": "Zimbabwe",
    "name_fr": "Zimbabwe",
    "name_nl": "Zimbabwe",
    "code": "ZW",
    "diallingCode": "+263"
  },
  {
    "name": "",
    "name_fr": "",
    "name_nl": "",
    "code": "",
    "diallingCode": null
  }
 ]