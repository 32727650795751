import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@fcr/azure-auth';
import { MultiLocaleTranslateService } from './services/multilocale-translate.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'scale-up.SPA';
  isLoginPage: boolean = false;

  constructor(
    private translate: MultiLocaleTranslateService, 
    private auth: AuthService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document){}

  ngOnInit(): void {
    this.translate.setLocaleByDomain('be_');
    this.auth.autoLogin();

    this.router.events.subscribe((event: any) => {
      if (event.url) {
        this.isLoginPage = this.checkLoginPage(event.url);
      }
    });

    // set lang attr to help cookie script recognize which translation should be used
    this.document.documentElement.lang = this.translate.currentLang.substring(3);
    this.translate.onLangChange.subscribe(() => {
      this.document.documentElement.lang = this.translate.currentLang.substring(3);
    });
  }

  checkLoginPage(url: string): boolean {
    return url === '/login';
  }
}
