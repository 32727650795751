<div mat-dialog-title>
    <mat-form-field floatLabel="always" appearance="outline">
        <mat-label>{{ "modals.countries.search" | translate }}</mat-label>
        <input matInput [formControl]="filterCntr" />
    </mat-form-field>

    <!-- <button matSuffix mat-icon-button  aria-label="Clear" (click)="clearValue('organisationName')" *ngIf="!!this.form.get('organisationName')?.value">
        <mat-icon svgIcon="close"></mat-icon>         
    </button> -->

</div>
<!-- / header -->

<div mat-dialog-content>

    <div *ngFor="let country of filteredCountries | async" (click)="countryChange(country)" class="row">
        <span style="width:24px;overflow:hide;display:block;float:left;">
        <img src="https://flagcdn.com/{{country.code.toLocaleLowerCase()}}.svg" height="16" />
        </span>
        <span style="margin-left: 16px;">
            {{country.name}}
        </span>
        <span style="float:right" *ngIf="this.data.showDialCode">{{country.diallingCode}}</span>
        <span style="float:right" *ngIf="this.data.showCountryCode">{{country.diallingCode}}</span>
    </div>

</div>
<!-- / body -->

<!-- <div mat-dialog-actions>
</div> -->
<!-- / footer -->
