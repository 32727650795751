<button *ngIf="btnType === 'submit'; else isNotSubmit;"
        [ngClass]="{
            'btn': btnStyle === 'outline' || btnStyle === 'filled-light' || btnStyle === 'filled-dark',
            'btn--light': btnStyle === 'filled-light',
            'btn--outline': btnStyle === 'outline',
            'btn--filled': btnStyle === 'filled-dark' || btnStyle === 'filled-light',
            'link-btn': btnStyle === 'link',
            'border-1': btnBorder === 'thin'
        }">

    <ng-container *ngIf="icon; else elseWithoutIcon">
        
        <!-- icon -->
        <app-icon [icon]="icon" svgClass="w-5 h-5"></app-icon>

        <!-- label -->
        <span class="mx-auto">
            {{ btnLabel }}
        </span>

    </ng-container>

    <ng-template #elseWithoutIcon>

        <!-- fake border label-->
        <ng-container *ngIf="fakeBorder; else elseFakeBorder">
            <span class="truncate lg:text-base text-sm ">{{ btnLabel }}</span>
            <span class="fake-border"></span>
        </ng-container>

        <!-- normal label -->
        <ng-template #elseFakeBorder>
            <span>{{ btnLabel }}</span>
        </ng-template>

    </ng-template>
</button>
<ng-template #isNotSubmit>
    <a [href]="btnHref"
        (click)="navigateTo($event)"
        [rel]="btnRel" 
        [target]="btnTarget"
        [ngClass]="{
            'btn': btnStyle === 'outline' || btnStyle === 'filled-light' || btnStyle === 'filled-dark',
            'btn--light': btnStyle === 'filled-light',
            'btn--outline': btnStyle === 'outline',
            'btn--filled': btnStyle === 'filled-dark' || btnStyle === 'filled-light',
            'link-btn': btnStyle === 'link',
            'border-1': btnBorder === 'thin'
        }">

        <ng-container *ngIf="icon && showIcon; else elseWithoutIcon">
            
            <!-- icon -->
            <app-icon [icon]="icon" svgClass="w-5 h-5"></app-icon>

            <!-- label -->
            <span class="mx-auto"
                [ngClass]="{ 'px-2' : btnStyle !== 'link' }">
                {{ btnLabel }}
            </span>

        </ng-container>

        <ng-template #elseWithoutIcon>

            <!-- fake border label-->
            <ng-container *ngIf="fakeBorder; else elseFakeBorder">
                <span class="truncate lg:text-base text-sm">{{ btnLabel }}</span>
                <span class="fake-border"></span>
            </ng-container>

            <!-- normal label -->
            <ng-template #elseFakeBorder>
                <span>{{ btnLabel }}</span>
            </ng-template>

        </ng-template>
    </a>
</ng-template>