import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@fcr/azure-auth';
import { TranslateService } from '@ngx-translate/core';
import { PlausibleTrackingService } from '../../services/plausible-tracking.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {

  brand: string;
  locale: string;

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private plausible: PlausibleTrackingService,
    private title: Title) { }

  ngOnInit(): void {
    this.locale = this.translate.currentLang.substring(3)
    this.brand = "mymedia"
    this.listenForSignIn()
    this.title.setTitle("Myfcrmedia | Login")
  }

  listenForSignIn() {
    this.authService.loggedIn.subscribe(x => {
      this.router.navigateByUrl("/")
    })
  }

  handleLogIn() {
    // track
    this.plausible.trackCustomEvent("LoginClick");
    // login
    this.authService.login(this.locale, this.brand);
  }
}
