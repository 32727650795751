<footer *ngIf="isLoginPage === false; else loginhPageFooter" id="footer">
    <div class="footer">
        <div class="su-container">

            <div class="grid grid-cols-1 md:grid-cols-6 gap-4">

                <!-- fcr logo -->
                <a href="{{'footer.Fcrmedia' | translate}}" class="mb-4 md:mb-0 row-start-5 md:row-start-1" target="_blank" rel="noopener noreferrer" [ariaLabel]="'Fcrmedia' | translate">
                    <app-icon [icon]="'fcr-footer-logo'" svgClass="w-[10rem] h-[6rem]"></app-icon>
                </a>

                <div class="flex flex-col row-start-6 md:row-start-1">
                    <h4 class="footer-heading">FCR Media Belgium {{ "footer.FCRafter" | translate}}</h4>
                    <p class="text-sm mb-1">Uitbreidingstraat 82<br>2600 {{ "footer.FCRantwerpen" | translate }}</p>
                    <a class="text-sm text-blue-200 hover:underline mb-1" href="//www.fcrmedia.be" target="_blank">www.fcrmedia.be</a>

                    <div class="flex">
                        <a href="https://www.facebook.com/FCRMediaBelgium/" class="p-2 w-8 h-8" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                            <app-icon [icon]="'facebook-icon'" svgClass="w-4 h-4" svgFill="#000"></app-icon>
                        </a>
                        <a href="https://www.linkedin.com/company/fcr-media-belgium/" class="p-2 w-8 h-8" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                            <app-icon [icon]="'linkedin-icon'" svgClass="w-4 h-4" svgFill="#000"></app-icon>
                        </a>
                        <a href="https://www.instagram.com/fcrmedia.be/" class="p-2 w-8 h-8" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                            <app-icon [icon]="'instagram-icon'" svgClass="w-4 h-4" svgFill="#000"></app-icon>
                        </a>
                        <a href="https://www.youtube.com/channel/UCk0ZN8F86873c0pt560U9ow/videos" class="p-2 w-8 h-8" target="_blank" rel="noopener noreferrer" aria-label="Youtube">
                            <app-icon [icon]="'youtube-icon'" svgClass="w-4 h-4" svgFill="#000"></app-icon>
                        </a>
                    </div>
                </div>

                <div *ngFor="let group of groups" class="flex flex-col md:row-start-1">
                    <h4 class="footer-heading">{{ group.title | translate }}</h4>
                    <ul>
                        <li *ngFor="let item of group.items">
                            <a href="{{item.url | translate}}" class="text-link" target="_blank" rel="noopener noreferrer">
                                {{item.title | translate}}
                            </a>
                        </li>
                    </ul>
                </div>

                 <!-- mobile language -->
                <ul class="mb-4 md:hidden md:mb-0 flex ml-auto -mt-[4rem]">                      
                    <app-language-switch></app-language-switch>
                </ul>  

            </div>

        </div>
    </div>

    <div class="su-container py-6">   

        <!-- message -->
        <div class="text-sm text-black text-center">
            <p class="mb-0">{{ footerMessage }}</p>
            <p>
                <a href="{{ 'footer.termsAndConditionsUrl' | translate}}" class="underline">{{ "footer.termsAndConditions" | translate }}</a> -
                <a href="{{ 'footer.privacyPolicyUrl' | translate }}" class="underline">{{ "footer.privacyPolicy" | translate }}</a> -
                <a href="{{ 'footer.cookiePolicyUrl' | translate }}" class="underline">{{ "footer.cookiePolicy" | translate }}</a> -
                <a href="{{ 'footer.whistleblowersUrl' | translate }}" class="underline">{{ "footer.whistleblowers" | translate }}</a>
            </p>
        </div>

    </div>
</footer>
<ng-template #loginhPageFooter>
    <footer class="border-t border-gray-300">
        <div class="su-container py-6">   

            <div class="flex items-center md:gap-8 justify-center md:flex-row flex-col">
                <a href="{{'footer.Fcrmedia' | translate}}" 
                    class="mb-4 md:mb-0 row-start-5 md:row-start-1" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    [ariaLabel]="'Fcrmedia' | translate">
                    <app-icon [icon]="'fcr-footer-logo'" svgClass="w-24 h-12"></app-icon>
                </a>

                <!-- message -->
                <div class="md:text-sm text-gray-400 text-center text-xs">
                    <p class="mb-0 md:block hidden">{{ footerMessage }}</p>
                    <p class="mb-0 md:hidden block">{{ footerMessageMobile }}</p>
                </div>
            </div>
    
        </div>
    </footer>
</ng-template>