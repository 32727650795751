<div class="listing-card">

    <div class="flex items-center gap-3">
        <i class="fas fa-building fa-2x text-su-blue md:flex hidden"></i>
        <div class="flex flex-col md:text-left text-center">
            <h2 class="text-xl text-black">{{ this.listing.companyName }}</h2>
            <p class="text-base text-su-gray">{{ this.listing.address.street }} {{ this.listing.address.housenumber }}, {{ this.listing.address.zip }} {{ this.listing.address.city }}</p>
        </div>
    </div>

    <div class="md:ml-auto flex md:gap-3 md:flex-row flex-col gap-4">
        <a (click)="trackSeeOnlineClick()"
            [href]="this.seeOnlineUrl" 
            target="_blank" 
            class="btn btn--small btn--outline">
            <i class="fas fa-eye mr-2"></i>
            {{ "body.seeOnline" | translate }}
        </a>
        <a class="btn btn--small btn--filled" (click)="handleEditClick()">
            <i class="fas fa-pen mr-2"></i>
            {{ "body.edit" | translate }}
        </a>
    </div>

</div>

<div *ngIf="this.toggleEdit"
    class="">
    <app-form 
        [lid]="this.listing.id"
        [instance]="'BE'"
        >
    </app-form>
</div>

