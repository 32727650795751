import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject, Subject } from 'rxjs';
import { CountryModel } from '../../../../../models/country.model';

export interface CountriesModalData {
  showDialCode: boolean;
  showCountryCode: boolean;
  countries: CountryModel[];
}

@Component({
  selector: 'app-countries-modal',
  templateUrl: './countries-modal.component.html',
  styleUrls: ['./countries-modal.component.scss']
})
export class CountriesModalComponent implements OnInit, OnDestroy {

  countryCntr: FormControl = new FormControl();
  filterCntr: FormControl = new FormControl();

  /** list of headings filtered by search keyword */
  public filteredCountries: ReplaySubject<CountryModel[]> = new ReplaySubject<CountryModel[]>(1);

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<CountriesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CountriesModalData
  ) {

    // load the initial countries list
    this.filteredCountries.next(this.data.countries.slice());

    this.filterCntr.valueChanges.subscribe(val => 
      {
        this.filterCountries();
      }
    );

  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  
  filterCountries() {
    if (!this.data.countries) {
      return;
    }
    // get the search keyword
    let search = this.filterCntr.value;
    if (!search) {
      this.filteredCountries.next(this.data.countries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter
    this.filteredCountries.next(
      this.data.countries.filter(c => c.name.toLowerCase().indexOf(search) > -1)
    );
  }

  // handleSetCountryOnEnter(event: KeyboardEvent) {
  //   if (event.key === 'Enter') {
  //     this.filteredCountries.subscribe((countries: CountryModel[]) => {
  //       // Zde můžete s polem countries pracovat dle vašich potřeb
  //       console.log('Pole CountryModel[]:', countries);

  //       if (countries.length === 1) {
  //         this.countryChange(countries[0]);
  //       }
  //     });
  //   }
  // }

  // @HostListener('window:keyup', ['$event'])
  // onDialogClick(event: KeyboardEvent): void {
  //   this.handleSetCountryOnEnter(event);
  // }

  countryChange(country: CountryModel) {
    this.dialogRef.close(country);
  }

}
