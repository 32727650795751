<mat-form-field floatLabel="always" appearance="outline" class="w-full">
    <mat-label>{{ this.label }}</mat-label>
    <span matPrefix style="margin-left:1em;">{{ this.displayPrefix }}</span>
    <input matInput [formControl]="this.profileCtrl" [placeholder]="this.placeholder" />
    <mat-hint>{{ "hints.published" | translate }}</mat-hint>
    <button matSuffix mat-icon-button class="small-icon-button" aria-label="Clear" (click)="clearValue()" *ngIf="this.profileCtrl.value">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
    <mat-error>
       {{ this.getError() | translate }}
    </mat-error>
</mat-form-field>