<div class="su-container">

    <div class="heading">
        <h1 class="md:py-[6.25rem] py-[3.5rem] md:text-left text-center">
            <span>{{ "body.title1" | translate }}</span>
            <br/>
            <span>{{ "body.title2" | translate }}</span>
        </h1>
    </div>

    <div class="flex flex-col gap-4 md:mb-52 mb-20" *ngIf="!this.loading && this.data.count > 0">
        <app-listing-card 
            *ngFor="let listing of this.data.listings" 
            [listing]="listing">
        </app-listing-card>
    </div>

    <div class="flex-flex-col md:mb-52 mb-20" *ngIf="!this.loading && this.data.count == 0">
        <h3 class="text-center">No listings assigned to your account</h3>
    </div>

    <div class="flex flex-col md:mb-52 mb-20 items-center" *ngIf="this.loading">
        <h3 class="text-center">Your data is loading..</h3>
        <div class="loader-container">
            <div class="loader"></div>
        </div>
    </div>

</div>