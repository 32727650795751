import { HttpInterceptor, HttpRequest, HttpHandler } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "@fcr/azure-auth";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) {}
    
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if(!this.authService.loggedToken) return next.handle(req);

        const authReq = req.clone({
            headers: req.headers
                        .set('Authorization', `Bearer ${this.authService.loggedToken.idToken}`)
        });
        return next.handle(authReq);
    }    
}