<div *ngIf="this.loading" class="spinner__container">
    <div class="loader"></div>
    <!-- <mat-spinner diameter="150"></mat-spinner> -->
</div>

<!-- sometimes it is necessary for the user to enter the code delivered by email campaign (ask-for-code="true") -->
<!-- ask for code -->
<div *ngIf="showAskForCodeForm">

    <form [formGroup]="this.askForCodeForm" 
        (ngSubmit)="openFormWithUserCode()" 
        class="ask-for-code">

        <div class="flex flex-col mb-3.5 form__input form__input--text">

            <!-- label -->
            <div class="label-shadow mb-4 mr-auto text-blue-600 text-shadow text-xl font-medium">
                {{ "askForCodeForm.codeFromEmail.label" | translate }}
            </div>

            <!-- codeFromEmail -->
            <mat-form-field floatLabel="always" appearance="outline" class="code-from-email">
                <input matInput 
                    formControlName="codeFromEmail" 
                    placeholder="{{ 'askForCodeForm.codeFromEmail.placeholder' | translate }}" />
                <button matSuffix mat-icon-button aria-label="Clear" 
                    (click)="clearInputValue('codeFromEmail')" 
                    *ngIf="!!this.askForCodeForm.get('codeFromEmail')?.value">
                    <mat-icon svgIcon="close"></mat-icon>
                </button>
                <mat-error>
                    {{ this.getInputErrorFor('codeFromEmail') | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="flex flex-col md:flex-row gap-4">
            
            <div class="w-full">
                <!-- submit and display form with preloaded data -->
                <button mat-button type="submit" class="btn btn--filled w-full">
                    {{ "askForCodeForm.submit.withCode.label" | translate }}
                </button>
            </div>

            <div class="w-full">
                <!-- display empty form -->
                <button type="button" mat-button class="btn btn--outline w-full" (click)="openEmptyForm()">
                    {{ "askForCodeForm.submit.withoutCode.label" | translate }}
                </button> 
            </div>

        </div>
    </form>

</div>
<!-- .ask for code -->

<div *ngIf="showForm">
    
    <h2 *ngIf="this.formTitle" [innerHTML]="this.formTitle" class="form-title"></h2>
    <div *ngIf="this.formText" [innerHTML]="this.formText" class="form-text"></div>

    <form [formGroup]="this.form" class="fcr-your-business-form">

        <section class="form-section">

            <mat-hint>{{ "hints.common" | translate }}</mat-hint>

            <!-- enterpriseNumber -->
            <div class="form-control" *ngIf="this.enterpriseNumberVisible">
                <svg viewBox="0 0 24 24" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M3 11h2v2H3zm0 6h2v2H3zM3 5h2v2H3zm16-3H7c-1.1 0-2 .9-2 2v1h2v2H5v4h2v2H5v4h2v2H5v1c0 1 1 2 2 2h12c1 0 2-1 2-2V4c0-1.1-1-2-2-2zm-6 5.5c1.3 0 2.3 1 2.3 2.3S14.3 12 13 12s-2.3-1-2.3-2.3 1-2.2 2.3-2.2zm4.5 9h-9v-1.1c0-1.5 3-2.3 4.5-2.3s4.5.8 4.5 2.3v1.1z"></path></svg>
                <div class="custom-error-wrapper">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{ "fields.enterpriseNumber.label" | translate }}</mat-label>
                        <input matInput formControlName="enterpriseNumber" />
                        <mat-hint>{{ "hints.notPublished" | translate }}</mat-hint>
                        <button matSuffix mat-icon-button aria-label="Clear" (click)="clearValue('enterpriseNumber')" *ngIf="!!this.form.get('enterpriseNumber')?.value">
                            <mat-icon svgIcon="close"></mat-icon>
                        </button>
                        <!-- <mat-error>
                            {{ this.getErrorFor('enterpriseNumber') | translate }}
                        </mat-error> -->
                    </mat-form-field>
                    <div class="error-message" *ngIf="!this.isFieldValid('enterpriseNumber')">
                        {{ this.getErrorFor('enterpriseNumber') | translate }}
                    </div>
                </div>
            </div>

            <!-- kvkNumber -->
            <div class="form-control" *ngIf="this.kvkNumberVisible">
                <svg viewBox="0 0 24 24" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M3 11h2v2H3zm0 6h2v2H3zM3 5h2v2H3zm16-3H7c-1.1 0-2 .9-2 2v1h2v2H5v4h2v2H5v4h2v2H5v1c0 1 1 2 2 2h12c1 0 2-1 2-2V4c0-1.1-1-2-2-2zm-6 5.5c1.3 0 2.3 1 2.3 2.3S14.3 12 13 12s-2.3-1-2.3-2.3 1-2.2 2.3-2.2zm4.5 9h-9v-1.1c0-1.5 3-2.3 4.5-2.3s4.5.8 4.5 2.3v1.1z"></path></svg>
                <div class="custom-error-wrapper">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{ "fields.kvkNumber.label" | translate }}</mat-label>
                        <input matInput formControlName="kvkNumber" />
                        <mat-hint>{{ "hints.notPublished" | translate }}</mat-hint>
                        <button matSuffix mat-icon-button aria-label="Clear" (click)="clearValue('kvkNumber')" *ngIf="!!this.form.get('kvkNumber')?.value">
                            <mat-icon svgIcon="close"></mat-icon>
                        </button>
                        <!-- <mat-error>
                            {{ this.getErrorFor('kvkNumber') | translate }}
                        </mat-error> -->
                    </mat-form-field>
                    <div class="error-message" *ngIf="!this.isFieldValid('kvkNumber')">
                        {{ this.getErrorFor('kvkNumber') | translate }}
                    </div>
                </div>
            </div>

            <!-- organisationName -->
            <div class="form-control">
                <svg xmlns="http://www.w3.org/2000/svg" id="mdi-office-building" viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M5,3V21H11V17.5H13V21H19V3H5M7,5H9V7H7V5M11,5H13V7H11V5M15,5H17V7H15V5M7,9H9V11H7V9M11,9H13V11H11V9M15,9H17V11H15V9M7,13H9V15H7V13M11,13H13V15H11V13M15,13H17V15H15V13M7,17H9V19H7V17M15,17H17V19H15V17Z"></path></svg>
                <div class="custom-error-wrapper">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{ "fields.organisationName.label" | translate }}</mat-label>
                        <input matInput formControlName="organisationName" />
                        <mat-hint>{{ "hints.published" | translate }}</mat-hint>
                        <button matSuffix mat-icon-button  aria-label="Clear" (click)="clearValue('organisationName')" *ngIf="!!this.form.get('organisationName')?.value">
                            <mat-icon svgIcon="close"></mat-icon>
                        </button>
                        <!-- <mat-error>
                            {{ this.getErrorFor('organisationName') | translate }}
                        </mat-error> -->
                    </mat-form-field>
                    <div class="error-message" *ngIf="!this.isFieldValid('organisationName')">
                        {{ this.getErrorFor('organisationName') | translate }}
                    </div>
                </div>
            </div>

            <!-- headingId -->
            <div class="form-control">
                <svg xmlns="http://www.w3.org/2000/svg" id="mdi-card-text" viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M20,20H4A2,2 0 0,1 2,18V6A2,2 0 0,1 4,4H20A2,2 0 0,1 22,6V18A2,2 0 0,1 20,20M5,13V15H16V13H5M5,9V11H19V9H5Z"></path></svg>
                <div class="custom-error-wrapper">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{ "fields.headingId.label" | translate }}</mat-label>
                        <mat-select [formControl]="this.headingCtrl" placeholder="" #headingSelect>
                            <mat-option>
                                <ngx-mat-select-search 
                                    [formControl]="this.headingFilterCtrl"
                                    [placeholderLabel]="('fields.headingId.placeholder' | translate)"
                                    [noEntriesFoundLabel]="('fields.headingId.noMatch' | translate)"
                                ></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let heading of this.filteredHeadings | async" [value]="heading">
                                {{heading.headingName}}
                            </mat-option>
                        </mat-select>
                        <mat-hint>{{ "hints.published" | translate }}</mat-hint>
                        <button matSuffix mat-icon-button aria-label="Clear" (click)="clearValue('headingId')" *ngIf="!!this.form.get('headingId')?.value">
                            <mat-icon svgIcon="close"></mat-icon>
                        </button>
                        <!-- <mat-error>
                            {{ this.getErrorFor('headingId') | translate }}
                        </mat-error> -->
                    </mat-form-field>
                    <div class="error-message" *ngIf="!this.isFieldValid('headingId')">
                        {{ this.getErrorFor('headingId') | translate }}
                    </div>
                </div>
            </div>

            <!-- ADDRESS ------------------------------- -->

            <!-- address : search -->
            <div class="form-control">
                <svg xmlns="http://www.w3.org/2000/svg" id="mdi-map-marker" viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z"></path></svg>
                <mat-form-field floatLabel="always" appearance="outline">
                    <mat-label>{{ "fields.addressSearch.label" | translate }}</mat-label>
                    <!-- <input matInput formControlName="addressSearch" #addressAutocomplete /> -->
                    <input matInput formControlName="addressSearch" #addressAutocomplete [matAutocomplete]="addressSuggest" />
                    <mat-autocomplete #addressSuggest="matAutocomplete" [displayWith]="selectAddressFn">
                        <mat-option *ngFor="let address of addressSuggestOptions" [value]="address">{{address.description}}</mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <!-- street row -->
            <div class="row row--street form-control">
                <!-- addressStreet -->
                <div class="custom-error-wrapper">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{ "fields.addressStreet.label" | translate }}</mat-label>
                        <input matInput formControlName="addressStreet" />
                        <!-- <mat-error>
                            {{ this.getErrorFor('addressStreet') | translate }}
                        </mat-error> -->
                    </mat-form-field>
                    <div class="error-message" *ngIf="!this.isFieldValid('addressStreet')">
                        {{ this.getAddressErrorFor('addressStreet') | translate }}
                    </div>
                </div>

                <!-- addressStreetNumber -->
                <div class="custom-error-wrapper">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{ "fields.addressStreetNumber.label" | translate }}</mat-label>
                        <input matInput formControlName="addressStreetNumber" />
                        <!-- <mat-error>
                            {{ this.getErrorFor('addressStreetNumber') | translate }}
                        </mat-error> -->
                    </mat-form-field>
                    <div class="error-message" *ngIf="!this.isFieldValid('addressStreetNumber')">
                        {{ this.getAddressErrorFor('addressStreetNumber') | translate }}
                    </div>
                </div>

                <!-- addressStreetDoor -->
                <div class="custom-error-wrapper">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{ "fields.addressStreetDoor.label" | translate }}</mat-label>
                        <input matInput formControlName="addressStreetDoor" />
                        <!-- <mat-error>
                            {{ this.getErrorFor('addressStreetDoor') | translate }}
                        </mat-error> -->
                    </mat-form-field>
                    <div class="error-message" *ngIf="!this.isFieldValid('addressStreetDoor')">
                        {{ this.getAddressErrorFor('addressStreetDoor') | translate }}
                    </div>
                </div>
            </div>
            <!-- .street row -->

            <!-- post row -->
            <div class="row row--post form-control">
                <!-- addressPostalCode -->
                <div class="custom-error-wrapper" [ngClass]="!this.isFieldValid('addressPostalCode') ? 'mb-4' : ''">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{ "fields.addressPostalCode.label" | translate }}</mat-label>
                        <input matInput formControlName="addressPostalCode" />
                        <!-- <mat-error>
                            {{ this.getErrorFor('addressPostalCode') | translate }}
                        </mat-error> -->
                    </mat-form-field>
                    <div class="error-message" *ngIf="!this.isFieldValid('addressPostalCode')">
                        {{ this.getAddressErrorFor('addressPostalCode') | translate }}
                    </div>
                </div>
                
                <!-- addressCity -->
                <div class="custom-error-wrapper">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{ "fields.addressCity.label" | translate }}</mat-label>
                        <input matInput formControlName="addressCity" />
                        <!-- <mat-error>
                            {{ this.getErrorFor('addressCity') | translate }}
                        </mat-error> -->
                    </mat-form-field>
                    <div class="error-message" *ngIf="!this.isFieldValid('addressCity')">
                        {{ this.getAddressErrorFor('addressCity') | translate }}
                    </div>
                </div>

                <!-- addressDistrict -->
                <div class="custom-error-wrapper">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{ "fields.addressDistrict.label" | translate }}</mat-label>
                        <input matInput formControlName="addressDistrict" />
                        <!-- <mat-error>
                            {{ this.getErrorFor('addressDistrict') | translate }}
                        </mat-error> -->
                    </mat-form-field>
                    <div class="error-message" *ngIf="!this.isFieldValid('addressDistrict')">
                        {{ this.getAddressErrorFor('addressDistrict') | translate }}
                    </div>
                </div>
            </div>
            <!-- .post row -->

            <!-- country -->
            <div class="form-control">
                <div class="custom-error-wrapper">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{ "fields.addressCountry.label" | translate }}</mat-label>
                        <input matInput type="text" readonly [formControl]="addressCountryNameCtrl" (click)="openCountries()" />
                        <mat-hint>{{ "hints.published" | translate }}</mat-hint>
                        <button matSuffix mat-icon-button aria-label="Clear" (click)="openCountries()">        
                            <img src="https://flagcdn.com/{{ this.addressCountry.code.toLocaleLowerCase() }}.svg" height="16" />
                        </button>    
                        <!-- <mat-error>
                            {{ this.getErrorFor('addressCountry') | translate }}
                        </mat-error> -->
                    </mat-form-field>
                    <div class="error-message" *ngIf="!this.isFieldValid('addressCountryNameCtrl')">
                        {{ this.getErrorFor('addressCountryNameCtrl') | translate }}
                    </div>
                </div>
            </div>

            <!-- /ADDRESS ------------------------------ -->


            <!-- email -->
            <div class="form-control">
                <svg xmlns="http://www.w3.org/2000/svg" id="mdi-email" viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"></path></svg>
                <div class="custom-error-wrapper">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{ "fields.email.label" | translate }}</mat-label>
                        <input matInput formControlName="email" />
                        <mat-hint>{{ "hints.published" | translate }}</mat-hint>
                        <button matSuffix mat-icon-button aria-label="Clear" (click)="clearValue('email')" *ngIf="!!this.form.get('email')?.value">
                            <mat-icon svgIcon="close"></mat-icon>
                        </button>
                        <!-- <mat-error>
                            {{ this.getErrorFor('email') | translate }}
                        </mat-error> -->
                    </mat-form-field>
                    <div class="error-message" *ngIf="!this.isFieldValid('email')">
                        {{ this.getErrorFor('email') | translate }}
                    </div>
                </div>
            </div>
                    
            <!-- fixedPhoneNumber -->
            <div class="form-control">
                <svg xmlns="http://www.w3.org/2000/svg" id="mdi-phone" viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z"></path></svg>
                <app-phone-input 
                    class="w-full"
                    [label]="('fields.fixedPhoneNumber.label' | translate)"
                    [countries]="this.countries"
                    [primaryCountry]="this.primaryCountry"
                    formControlName="fixedPhoneNumber"
                    [otherPhoneCtrl]="this.mobilePhoneNumberCtrl"
                    [otherPhone]="mobilePhone"
                    (inputBlur)="updatePhonesValidation()"
                    [showErrors]="this.showErrors"
                    #fixedPhone
                />
            </div>

            <!-- mobilePhoneNumber -->
            <div class="form-control">
                <svg xmlns="http://www.w3.org/2000/svg" id="mdi-cellphone" viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M17,19H7V5H17M17,1H7C5.89,1 5,1.89 5,3V21A2,2 0 0,0 7,23H17A2,2 0 0,0 19,21V3C19,1.89 18.1,1 17,1Z"></path></svg>
                <app-phone-input 
                    class="w-full"
                    [label]="('fields.mobilePhoneNumber.label' | translate)"
                    [countries]="this.countries"
                    [primaryCountry]="this.primaryCountry"
                    formControlName="mobilePhoneNumber"
                    [otherPhoneCtrl]="this.fixedPhoneNumberCtrl"
                    [otherPhone]="fixedPhone"
                    (inputBlur)="updatePhonesValidation()"
                    [showErrors]="this.showErrors"
                    #mobilePhone
                />
                <!-- 
                        [formControlRef]="this.form.get('mobilePhoneNumber')"
                -->
                <!-- {{ this.form.get('fixedPhoneNumber')?.value }} -->
            </div>
            <!-- {{ this.form.get('fixedPhoneNumber')?.value }} -->
    
            <!-- website -->
            <div class="form-control">
                <svg xmlns="http://www.w3.org/2000/svg" id="mdi-web" viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M16.36,14C16.44,13.34 16.5,12.68 16.5,12C16.5,11.32 16.44,10.66 16.36,10H19.74C19.9,10.64 20,11.31 20,12C20,12.69 19.9,13.36 19.74,14M14.59,19.56C15.19,18.45 15.65,17.25 15.97,16H18.92C17.96,17.65 16.43,18.93 14.59,19.56M14.34,14H9.66C9.56,13.34 9.5,12.68 9.5,12C9.5,11.32 9.56,10.65 9.66,10H14.34C14.43,10.65 14.5,11.32 14.5,12C14.5,12.68 14.43,13.34 14.34,14M12,19.96C11.17,18.76 10.5,17.43 10.09,16H13.91C13.5,17.43 12.83,18.76 12,19.96M8,8H5.08C6.03,6.34 7.57,5.06 9.4,4.44C8.8,5.55 8.35,6.75 8,8M5.08,16H8C8.35,17.25 8.8,18.45 9.4,19.56C7.57,18.93 6.03,17.65 5.08,16M4.26,14C4.1,13.36 4,12.69 4,12C4,11.31 4.1,10.64 4.26,10H7.64C7.56,10.66 7.5,11.32 7.5,12C7.5,12.68 7.56,13.34 7.64,14M12,4.03C12.83,5.23 13.5,6.57 13.91,8H10.09C10.5,6.57 11.17,5.23 12,4.03M18.92,8H15.97C15.65,6.75 15.19,5.55 14.59,4.44C16.43,5.07 17.96,6.34 18.92,8M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"></path></svg>
                <div class="custom-error-wrapper">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{ "fields.website.label" | translate }}</mat-label>
                        <input matInput formControlName="website" />
                        <mat-hint>{{ "hints.published" | translate }}</mat-hint>
                        <button matSuffix mat-icon-button aria-label="Clear" (click)="clearValue('website')" *ngIf="!!this.form.get('website')?.value">
                            <mat-icon svgIcon="close"></mat-icon>
                        </button>
                        <!-- <mat-error>
                            {{ this.getErrorFor('website') | translate }}
                        </mat-error> -->
                    </mat-form-field>
                    <div class="error-message" *ngIf="!this.isFieldValid('website')">
                        {{ this.getErrorFor('website') | translate }}
                    </div>
                </div>
            </div>
                    
            <!-- webshop -->
            <div class="form-control">
                <svg xmlns="http://www.w3.org/2000/svg" id="mdi-cart-outline" viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M17,18A2,2 0 0,1 19,20A2,2 0 0,1 17,22C15.89,22 15,21.1 15,20C15,18.89 15.89,18 17,18M1,2H4.27L5.21,4H20A1,1 0 0,1 21,5C21,5.17 20.95,5.34 20.88,5.5L17.3,11.97C16.96,12.58 16.3,13 15.55,13H8.1L7.2,14.63L7.17,14.75A0.25,0.25 0 0,0 7.42,15H19V17H7C5.89,17 5,16.1 5,15C5,14.65 5.09,14.32 5.24,14.04L6.6,11.59L3,4H1V2M7,18A2,2 0 0,1 9,20A2,2 0 0,1 7,22C5.89,22 5,21.1 5,20C5,18.89 5.89,18 7,18M16,11L18.78,6H6.14L8.5,11H16Z"></path></svg>
                <div class="custom-error-wrapper">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{ "fields.webshop.label" | translate }}</mat-label>
                        <input matInput formControlName="webshop" />
                        <mat-hint>{{ "hints.published" | translate }}</mat-hint>
                        <button matSuffix mat-icon-button aria-label="Clear" (click)="clearValue('webshop')" *ngIf="!!this.form.get('webshop')?.value">
                            <mat-icon svgIcon="close"></mat-icon>
                        </button>
                        <!-- <mat-error>
                            {{ this.getErrorFor('webshop') | translate }}
                        </mat-error> -->
                    </mat-form-field>
                    <div class="error-message" *ngIf="!this.isFieldValid('webshop')">
                        {{ this.getErrorFor('webshop') | translate }}
                    </div>
                </div>
            </div>
        
            <!-- facebook -->
            <div class="form-control">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M12 2.04c-5.5 0-10 4.49-10 10.02 0 5 3.66 9.15 8.44 9.9v-7H7.9v-2.9h2.54V9.85c0-2.51 1.49-3.89 3.78-3.89 1.09 0 2.23.19 2.23.19v2.47h-1.26c-1.24 0-1.63.77-1.63 1.56v1.88h2.78l-.45 2.9h-2.33v7a10 10 0 0 0 8.44-9.9c0-5.53-4.5-10.02-10-10.02Z"></path></svg>
                <app-social-input
                    class="w-full"
                    formControlName="facebook" 
                    [label]="('fields.facebook.label' | translate)"
                    [placeholder]="( 'fields.facebook.placeholder' | translate )"
                    displayPrefix="facebook.com/"
                    urlPrefix="https://www.facebook.com/"
                >
                </app-social-input>
            </div>

            <!-- instagram -->
            <div class="form-control">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8 1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5 5 5 0 0 1-5 5 5 5 0 0 1-5-5 5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3Z"></path></svg>
                <app-social-input
                    class="w-full"
                    formControlName="instagram" 
                    [label]="('fields.instagram.label' | translate)"
                    [placeholder]="( 'fields.instagram.placeholder' | translate )"
                    displayPrefix="instagram.com/"
                    urlPrefix="https://www.instagram.com/"
                >
            </app-social-input>
        
            </div>
                    
            <!-- linkedIn -->
            <div class="form-control">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77Z"></path></svg>
                <app-social-input
                    class="w-full"
                    formControlName="linkedIn" 
                    [label]="('fields.linkedIn.label' | translate)"
                    [placeholder]="( 'fields.linkedIn.placeholder' | translate )"
                    displayPrefix="linkedin.com/"
                    urlPrefix="https://www.linkedin.com/"
                >
                </app-social-input>    
            </div>

            <!-- opening hours -->
            <div class="custom-error-wrapper">
                <app-opening-hours
                formControlName="openingHours"
                [lid]="this.listingId"
                ></app-opening-hours>
                <div class="error-message" style="bottom: 2rem" *ngIf="!this.isFieldValid('openingHours')">
                    {{ this.getErrorForOpeningHours('openingHours') }}
                </div>
            </div>
            <!-- 
            <div>
                OH: {{ toJson(this.form.get('openingHours')?.value) }}
            </div> -->

            <!-- opening hours, additional text -->
            <div class="form-control">
                <svg xmlns="http://www.w3.org/2000/svg" id="mdi-card-text" viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M20,20H4A2,2 0 0,1 2,18V6A2,2 0 0,1 4,4H20A2,2 0 0,1 22,6V18A2,2 0 0,1 20,20M5,13V15H16V13H5M5,9V11H19V9H5Z"></path></svg>
                <div class="custom-error-wrapper custom-error-wrapper--long">
                    <mat-form-field floatLabel="always" appearance="outline">
                        <mat-label>{{ "fields.openingHoursAdditionalInfo.label" | translate }}</mat-label>
                        <input matInput formControlName="openingHoursAdditionalInfo" />
                        <mat-hint>{{ "hints.ohPublished" | translate }}</mat-hint>
                        <button matSuffix mat-icon-button aria-label="Clear" (click)="clearValue('openingHoursAdditionalInfo')" *ngIf="!!this.form.get('openingHoursAdditionalInfo')?.value">
                            <mat-icon svgIcon="close"></mat-icon>
                        </button>
                        <!-- <mat-error>
                            {{ this.getErrorFor('openingHoursAdditionalInfo') | translate }}
                        </mat-error> -->
                    </mat-form-field>   
                    <div class="error-message" *ngIf="!this.isFieldValid('openingHoursAdditionalInfo')">
                        {{ this.getErrorFor('openingHoursAdditionalInfo') | translate }}
                    </div>
                </div>     
            </div>
        </section>

        <!-- errors -->
        <section *ngIf="this.showErrors && this.getAllErrors().length" class="form-section">
            <mat-error *ngFor="let error of this.getAllErrors()">{{ error | translate }}</mat-error>            
        </section>

        <!-- submit -->
        <section class="text-center">
            <button mat-raised-button color="primary" (click)="postData()">
                {{ "form.submit" | translate }}
            </button>
        </section>
        <!-- submit -->
    </form>
</div>

<div *ngIf="showSummary" #summary>

    <h2 class="form-title">{{ "summary.title" | translate }}</h2>
    <div class="form-text" [innerHTML]="getTranslatedText('summary.textAbove')"></div>

    <dl class="dl-flex">
        <!-- basic info -->
        <ng-container *ngIf="this.formData.enterpriseNumber && this.instance == 'BE'">
            <dt>{{ "fields.enterpriseNumber.label" | translate }}</dt>
            <dd>{{ this.formData.enterpriseNumber }}</dd>
        </ng-container>

        <ng-container *ngIf="this.formData.kvkNumber && this.instance == 'NL'">
            <dt>{{ "fields.kvkNumber.label" | translate }}</dt>
            <dd>{{ this.formData.kvkNumber }}</dd>
        </ng-container>
        
        <ng-container *ngIf="this.formData.organisationName">
            <dt>{{ "fields.organisationName.label" | translate }}</dt>
            <dd>{{ this.formData.organisationName }}</dd>
        </ng-container>
        
        <ng-container *ngIf="this.formData.heading?.headingName">
            <dt>{{ "fields.headingId.label" | translate }}</dt>
            <dd>{{ this.formData.heading?.headingName }}</dd>
        </ng-container>
        
        <!-- contact person -->
        <ng-container *ngIf="this.formData.firstName">
            <dt>{{ "fields.firstName.label" | translate }}</dt>
            <dd>{{ this.formData.firstName }}</dd>
        </ng-container>
        
        <ng-container *ngIf="this.formData.lastName">
            <dt>{{ "fields.lastName.label" | translate }}</dt>
            <dd>{{ this.formData.lastName }}</dd>
        </ng-container>
        
        <ng-container *ngIf="this.formData.headingId">
            <dt>{{ "fields.headingId.label" | translate }}</dt>
            <dd>{{ getHeadingForSummaryPage() }}</dd>
        </ng-container>

        <ng-container *ngIf="this.formData.addressStreet">
            <dt>{{ "fields.addressStreet.label" | translate }}</dt>
            <dd>{{ this.formData.addressStreet }}</dd>
        </ng-container>
        
        <ng-container *ngIf="this.formData.addressStreetNumber">
            <dt>{{ "fields.addressStreetNumber.label" | translate }}</dt>
            <dd>{{ this.formData.addressStreetNumber }}</dd>
        </ng-container>
        
        <ng-container *ngIf="this.formData.addressStreetDoor">
            <dt>{{ "fields.addressStreetDoor.label" | translate }}</dt>
            <dd>{{ this.formData.addressStreetDoor }}</dd>
        </ng-container>
        
        <ng-container *ngIf="this.formData.addressCity">
            <dt>{{ "fields.addressCity.label" | translate }}</dt>
            <dd>{{ this.formData.addressCity }}</dd>
        </ng-container>
        
        <ng-container *ngIf="this.formData.addressDistrict">
            <dt>{{ "fields.addressDistrict.label" | translate }}</dt>
            <dd>{{ this.formData.addressDistrict }}</dd>
        </ng-container>
        
        <ng-container *ngIf="this.formData.addressPostalCode">
            <dt>{{ "fields.addressPostalCode.label" | translate }}</dt>
            <dd>{{ this.formData.addressPostalCode }}</dd>
        </ng-container>
        
        <ng-container *ngIf="this.formData.addressCountry">
            <dt>{{ "fields.addressCountry.label" | translate }}</dt>
            <dd>{{ this.formData.addressCountry }}</dd>
        </ng-container>
        
        <!-- contacts -->
        <ng-container *ngIf="this.formData.email">
            <dt>{{ "fields.email.label" | translate }}</dt>
            <dd>{{ this.formData.email }}</dd>
        </ng-container>
        
        <ng-container *ngIf="this.formData.fixedPhoneNumber">
            <dt>{{ "fields.fixedPhoneNumber.label" | translate }}</dt>
            <dd>{{ this.formData.fixedPhoneNumber }}</dd>
        </ng-container>
        
        <ng-container *ngIf="this.formData.mobilePhoneNumber">
            <dt>{{ "fields.mobilePhoneNumber.label" | translate }}</dt>
            <dd>{{ this.formData.mobilePhoneNumber }}</dd>
        </ng-container>
        
        <ng-container *ngIf="this.formData.website">
            <dt>{{ "fields.website.label" | translate }}</dt>
            <dd>{{ this.formData.website }}</dd>
        </ng-container>
        
        <ng-container *ngIf="this.formData.webshop">
            <dt>{{ "fields.webshop.label" | translate }}</dt>
            <dd>{{ this.formData.webshop }}</dd>
        </ng-container>
        
        <ng-container *ngIf="this.formData.facebook">
            <dt>{{ "fields.facebook.label" | translate }}</dt>
            <dd>{{ this.formData.facebook }}</dd>
        </ng-container>
        
        <ng-container *ngIf="this.formData.instagram">
            <dt>{{ "fields.instagram.label" | translate }}</dt>
            <dd>{{ this.formData.instagram }}</dd>
        </ng-container>
        
        <ng-container *ngIf="this.formData.linkedIn">
            <dt>{{ "fields.linkedIn.label" | translate }}</dt>
            <dd>{{ this.formData.linkedIn }}</dd>
        </ng-container>
      
        <!-- opening hours -->
        <ng-container *ngFor="let item of this.formData.openingHours">
            <ng-container *ngIf="item.weekDay && item.fromTime && item.tillTime">
                <dt>{{ "weekdays."+item.weekDay.toLocaleLowerCase() | translate }}</dt>
                <dd>{{ item.fromTime }} - {{ item.tillTime }}</dd>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="this.formData.openingHoursAdditionalInfo">
            <dt>{{ "fields.openingHoursAdditionalInfo.label" | translate }}</dt>
            <dd>{{ this.formData.openingHoursAdditionalInfo }}</dd>
        </ng-container>

    </dl>

    <div class="form-text">{{ "summary.textBelow" | translate }}</div>


</div>

<div *ngIf="showLoadError" class="message message--error">
    Data loading error!
</div>

<div id="listing-form-root"></div>


<!-- <hr />
<hr />
<button (click)="this.authService.register()">register</button>
<button (click)="this.authService.login()">login</button>
<button (click)="this.authService.signOut()">signOut</button> -->
