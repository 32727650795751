import * as i0 from '@angular/core';
import { EventEmitter, Injectable, Inject, Output } from '@angular/core';
import * as i1 from '@angular/common/http';
const LS_TOKEN_KEY = 'LS_TOKEN_KEY';
class AuthService {
  httpClient;
  baseApiUrl;
  loggedUser;
  loggedToken;
  loggedUserId;
  _isUserLogged = false;
  loggedIn = new EventEmitter();
  loggedOut = new EventEmitter();
  popup;
  LOGIN_URL = () => `${this.baseApiUrl}/api/auth/login`;
  REGISTER_URL = () => `${this.baseApiUrl}/api/auth/register`;
  LOGOUT_URL = () => `${this.baseApiUrl}/api/auth/logout`;
  AQUIRE_TOKEN_SILENT_URL = () => `${this.baseApiUrl}/api/auth/me`;
  REFRESH_TOKEN_URL = () => `${this.baseApiUrl}/api/auth/refresh`;
  VERIFY_URL = () => `${this.baseApiUrl}/api/auth/verify`;
  constructor(httpClient, baseApiUrl) {
    this.httpClient = httpClient;
    this.baseApiUrl = baseApiUrl;
    window.addEventListener('message', event => {
      console.log('meessage received: ', event);
      if (event.origin !== this.baseApiUrl) return;
      if (event.data.message) {
        // TODO: Error handling
      }
      const loginResponse = event.data;
      //console.log(loginResponse)
      if (loginResponse.isAuthorized && loginResponse.token) {
        this.setSignedInUser(loginResponse.token);
      } else {
        // TODO: Error handling
      }
    }, false);
  }
  isUserLoggedIn() {
    return this._isUserLogged;
  }
  // LOGIN - normal
  login(locale, brand, state) {
    if (this._isUserLogged) return;
    this.popup = window.open(this.LOGIN_URL() + this.generateQuery({
      locale: locale,
      brand: brand,
      state: state
    }), '__sign_in', this.generatePopupArgs());
  }
  // REGISTER
  register(locale, brand, state) {
    this.popup = window.open(this.REGISTER_URL() + this.generateQuery({
      locale: locale,
      brand: brand,
      state: state
    }), '__register', this.generatePopupArgs());
  }
  // TRY TO AUTOLOGIN / ME
  autoLogin() {
    if (this._isUserLogged) return;
    const token = localStorage.getItem(LS_TOKEN_KEY) ?? '';
    if (token) {
      try {
        const localToken = JSON.parse(token);
        this.setSignedInUser(localToken);
        return;
      } catch {
        console.log('Parsing token from local storage failed!');
      }
    }
  }
  // Gets token from .Net Auth service
  aquireTokenSilentUrl() {
    this.httpClient.get(this.AQUIRE_TOKEN_SILENT_URL()).subscribe({
      next: token => {
        console.log(token);
        this.setSignedInUser(token);
      },
      error: error => {
        this.localSignOut();
        console.log('Error while aquiring token silent', error);
        // TODO: Error handling
      }
    });
  }
  // REFRESH TOKEN
  refreshToken(brand) {
    const currentRefreshToken = this.loggedToken?.refreshToken;
    if (!currentRefreshToken) return undefined;
    const urlBuilder = new URL(this.REFRESH_TOKEN_URL());
    urlBuilder.searchParams.append('brand', brand);
    urlBuilder.searchParams.append('refreshToken', currentRefreshToken);
    this.httpClient.get(urlBuilder.toString()).subscribe({
      next: token => {
        console.log(token);
        if (!this.loggedToken || !this.loggedUser) return;
        this.loggedToken = token;
        const newToken = {
          idToken: token.idToken,
          refreshToken: token.refreshToken,
          tokenType: token.tokenType,
          expirationTime: token.expirationTime,
          refreshTokenExpirationTime: token.refreshTokenExpirationTime,
          userInfo: this.loggedUser
        };
        localStorage.setItem(LS_TOKEN_KEY, JSON.stringify(newToken));
      },
      error: error => {
        // this.localSignOut();
        console.log('Error while refreshing token', error);
      }
    });
  }
  // LOGOUT
  signOut() {
    if (!this._isUserLogged) return;
    this.localSignOut();
    this.remoteSignOut();
  }
  // Clears cookies remotly
  remoteSignOut() {
    this.httpClient.post(this.LOGOUT_URL(), null).subscribe({
      next: res => {
        console.log('RemoteLogoutSuccessfull');
      },
      error: error => {
        console.log('Remote logout failed!', error);
      }
    });
  }
  // TESTING PURPOSES ONLY
  clearCurrentUser() {
    this.loggedUser = undefined;
  }
  verify() {
    if (!this._isUserLogged || !this.loggedToken) return undefined;
    this.httpClient.get(this.VERIFY_URL(), {
      headers: {
        Authorization: `Bearer ${this.loggedToken.idToken}`
      }
    }).subscribe({
      next: res => {
        console.log('Verify successful');
        this.loggedUser = res;
      },
      error: error => {
        console.log('Verify failed!', error);
        this.localSignOut();
      }
    });
    return this.loggedUser;
  }
  // Clears local storage
  localSignOut() {
    // console.log('Local sign out...');
    localStorage.removeItem(LS_TOKEN_KEY);
    this.loggedToken = undefined;
    this.loggedUser = undefined;
    this._isUserLogged = false;
    this.loggedOut.emit(true);
    this.loggedUserId = undefined;
  }
  // Set Signed In User
  setSignedInUser(token) {
    if (!token || !token.userInfo) {
      this.localSignOut();
      return;
    }
    this._isUserLogged = true;
    this.loggedUser = token.userInfo;
    this.loggedToken = token;
    localStorage.setItem(LS_TOKEN_KEY, JSON.stringify(token));
    this.loggedIn.emit(token.userInfo);
  }
  generateQuery(params) {
    if (!params) return '';
    return Object.keys(params).reduce((res, k) => {
      const v = params[k];
      if (!v) return res;
      res += res ? '&' : '?';
      res += `${k}=${encodeURIComponent(v)}`;
      return res;
    }, '');
  }
  generatePopupArgs() {
    const w = 390;
    const h = 500;
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;
    return `scrollbars=no,width=${w / systemZoom},height=${h / systemZoom},top=${top},left=${left},menubar=1,resizable=1`;
  }
  static ɵfac = function AuthService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AuthService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject('fcr-auth-api-url'));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: AuthService,
    factory: AuthService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['fcr-auth-api-url']
    }]
  }], {
    loggedIn: [{
      type: Output
    }],
    loggedOut: [{
      type: Output
    }]
  });
})();
class WithCredentialsInterceptor {
  intercept(req, next) {
    const modifiedRequest = req.clone({
      withCredentials: true
    });
    return next.handle(modifiedRequest);
  }
  static ɵfac = function WithCredentialsInterceptor_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || WithCredentialsInterceptor)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: WithCredentialsInterceptor,
    factory: WithCredentialsInterceptor.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WithCredentialsInterceptor, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/*
 * Public API Surface of fcr-azure-auth
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AuthService, WithCredentialsInterceptor };
