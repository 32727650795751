import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-social-input',
  templateUrl: './social-input.component.html',
  styleUrls: ['./social-input.component.scss']
})
export class SocialInputComponent implements ControlValueAccessor, OnInit {
  @Input() label: string = "";  
  @Input() urlPrefix: string = "http://";
  @Input() displayPrefix: string = "http://";
  @Input() placeholder: string = "my-profile";  

  touched = false;
  onChange = (_: any) => {};
  onTouched = () => {};

  profileCtrl: FormControl<string | null>;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
  ) {

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
      this.ngControl.valueChanges          
    }

    this.profileCtrl = new FormControl(null, [this.dummyValidator()]);      

    this.profileCtrl.valueChanges.subscribe(val => {
      // we have to add it to prefix and set back to control
      const url = (val)
       ? `${[this.urlPrefix]}${val}`
       : "";
      this.onChange(url);
      this.onTouched();
      this.profileCtrl.markAsTouched();
    })

  }

  ngOnInit() {

    this.ngControl.control?.statusChanges.subscribe(s => {
      this.profileCtrl.setErrors(this.ngControl.errors);
    });

  }

  writeValue(val: string | null): void {
    // we have to strip the Url prefix and then set the value
    const profile = (val && val.startsWith(this.urlPrefix))
      ? val.substring(this.urlPrefix.length)
      : val;

    // (emitEvent: false) - avoid re-triggering valueChanges
    this.profileCtrl.setValue(profile, { emitEvent: false }); 
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    (isDisabled)
      ? this.profileCtrl.disable()
      : this.profileCtrl.enable();
  }


  clearValue() {
    this.profileCtrl.setValue("");
  }

  getError() : string {
    return `fields.${this.ngControl.name}.pattern`;
  }

  dummyValidator(): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {
      return null;
    }  
  }

}

