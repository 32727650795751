import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent implements OnInit {
  @Input() isLoginPage: boolean = false;

  groups: { title: string; items: LinkItem[] }[] = [];
  footerMessage: string = '';
  footerMessageMobile: string = '';

  constructor(
    private router: Router,
    private translate: TranslateService){}

  ngOnInit(): void {
    this.fillGroups();

    this.translate.onLangChange.subscribe(() => {
      console.log("onLangChange");
      this.getFooterMessage();
    });
  }

  getFooterMessage() {
    this.translate.get('footer.message').subscribe((message: string) => {
      this.footerMessage = this.replaceYear(message)
    });
    this.translate.get('footer.mobileMessage').subscribe((message: string) => {
      this.footerMessageMobile = this.replaceYear(message);
    });
  }

  replaceYear(message: string): string {
    const date = new Date();
    return message.replace('{year}', date.getFullYear().toString());
  }

  fillGroups() {
    const groupsData = [
      {
        title: 'footer.group1title',
        items: [
          { title: 'footer.group1item1', url: 'footer.group1item1url' },
          { title: 'footer.group1item2', url: 'footer.group1item2url' },
          { title: 'footer.group1item3', url: 'footer.group1item3url' },
          { title: 'footer.group1item4', url: 'footer.group1item4url' },
          { title: 'footer.group1item5', url: 'footer.group1item5url' },
          { title: 'footer.group1item6', url: 'footer.group1item6url' },
          { title: 'footer.group1item7', url: 'footer.group1item7url' },
          { title: 'footer.group1item8', url: 'footer.group1item8url' },
          { title: 'footer.group1item9', url: 'footer.group1item9url' },
          { title: 'footer.group1item10', url: 'footer.group1item10url' },
          { title: 'footer.group1item11', url: 'footer.group1item11url' },
          { title: 'footer.group1item12', url: 'footer.group1item12url' },
          { title: 'footer.group1item13', url: 'footer.group1item13url' }
        ]
      },
      {
        title: 'footer.group2title',
        items: [
          { title: 'footer.group2item1', url: 'footer.group2item1url' },
          { title: 'footer.group2item2', url: 'footer.group2item2url' },
          { title: 'footer.group2item3', url: 'footer.group2item3url' },
          { title: 'footer.group2item4', url: 'footer.group2item4url' }
        ]
      },
      {
        title: 'footer.group3title',
        items: [
          { title: 'footer.group3item1', url: 'footer.group3item1url' },
          { title: 'footer.group3item2', url: 'footer.group3item2url' },
          { title: 'footer.group3item3', url: 'footer.group3item3url' },
          { title: 'footer.group3item4', url: 'footer.group3item4url' }
        ]
      },
      {
        title: 'footer.group4title',
        items: [
          { title: 'footer.group4item1', url: 'footer.group4item1url' },
          { title: 'footer.group4item2', url: 'footer.group4item2url' },
          { title: 'footer.group4item3', url: 'footer.group4item3url' }
        ]
      }
    ];
  
    this.groups = groupsData.map(group => ({
      title: group.title,
      items: this.filterGroupItems(group.items)
    }));
  }

  filterGroupItems(items: { title: string; url: string }[]): LinkItem[] {
    return items
      .map(item => {
        const translatedUrl = this.translate.instant(item.url);
        return translatedUrl ? new LinkItem(item.title, item.url) : null;
      })
      .filter(item => item !== null) as LinkItem[];
  }
}

class LinkItem {
    title: string = '';
    url: string = '';
    
    constructor(_title: any, _url: any){
      this.title = _title;
      this.url = _url;
    }
}