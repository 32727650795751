<div class="custom-error-wrapper">
    <mat-form-field floatLabel="always" appearance="outline">
        <mat-label>{{ this.label }}</mat-label>
        <button matPrefix mat-icon-button class="small-icon-button small-icon-button--left-gap" aria-label="Clear" (click)="openCountries()">
            <img src="{{ this.getFlagSrc() }}" height="16" />
        </button>
        <input matInput [formControl]="this.phoneCntr" (blur)="formatValue()" (focus)="stripValue()" />
        <mat-hint>{{ "hints.published" | translate }}</mat-hint>
        <button matSuffix mat-icon-button class="small-icon-button" aria-label="Clear" (click)="clearValue()" *ngIf="this.phoneCntr.value">
            <mat-icon svgIcon="close"></mat-icon>
        </button>
        <!-- <mat-error>{{ getError() | translate }}</mat-error> -->
    </mat-form-field>
    <div class="error-message" *ngIf="!this.isFieldValid()">
        {{ getError() | translate }}
    </div>
</div>
<!-- val: {{ this.ngControl.control?.value  }} -->
